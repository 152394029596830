import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingController, AlertController, ModalController, Platform, IonModal, ActionSheetController, IonicModule } from '@ionic/angular';
import { AssinaturaService } from '../api/assinatura.service';
import { ComissaoService } from '../api/comissao.service';
import { ProjetoService } from '../api/projeto.service';
import { UsuarioService } from '../api/usuario.service';
import { ComissaoPage } from '../comissao/comissao.page';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { PageBaseService } from '../util/page-base.service';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { toastController } from '@ionic/core';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { IconeCopiarComponent } from '../icone-copiar/icone-copiar.component';
import { IconeEmentaComponent } from '../icone-ementa/icone-ementa.component';

@Component({
    selector: 'app-agenda',
    templateUrl: './agenda.page.html',
    styleUrls: ['./agenda.page.scss'],
    imports: [
        NgIf,
        IonicModule,
        NgFor,
        IconeCopiarComponent,
        IconeEmentaComponent,
        DatePipe,
    ],
})
export class AgendaPage extends PageBaseService implements OnInit {
  @Input()
  agenda: any;
  plano: any;
  constructor(
    private usuarioService: UsuarioService,
    private projetoService: ProjetoService,
    public alertCtrl: AlertController,
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
    private assinaturaService: AssinaturaService,
    private comissaoService: ComissaoService,
    protected loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private calendar: Calendar
  ) {
    super(loadingController, alertCtrl, null, null);
  }

  formatarHorario(input: string) {
    const parts = input.split(':');
    return parts[0] + ':' + parts[1];
  }
  ngOnInit() {
    try {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {
          this.plano = data;
          if (!this.plano) this.assinaturaService.assinar();
        });
    } catch (error) {}
  }
  async proposicaoClick(proposicao) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({
        Id: proposicao.Id,
        IdUsuario: this.usuarioService.get() ? this.usuarioService.get().Id : 0,
      })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }
  onImgError(item) {
    item.UrlFotoAutor = '/assets/imagem-perfil.jpg';
  }
  comissaoClick(comissao: any) {
    try {
      this.usuarioService
        .obterPlano(this.usuarioService.get().Id)
        .then((data) => {
          this.plano = data;
          if (!this.plano) this.assinaturaService.assinar();
        });
    } catch (error) {}
    this.exibirLoading('Carregando...');
    this.comissaoService.obter({ Id: comissao.Id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ComissaoPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          comissao: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }
  async abrirMenu() {
    const evento = {
      title:
        this.agenda.Tipo +
        ' - ' +
        (this.agenda.Comissao ? this.agenda.Comissao.Nome + ' - ' : '') +
        (this.agenda.Camara ? 'Câmara dos Deputados' : 'Senado Federal'),
      location: this.agenda.Local,
      description:
        this.agenda.Descricao +
        (this.agenda.Link ? ' - ' + this.agenda.Link : ''),
      start_time: new Date(this.agenda.Data),
      end_time: new Date(this.agenda.Data),
    };
    console.log(evento);
    var calOptions = this.calendar.getCalendarOptions(); // grab the defaults
    calOptions.calendarName = 'Agendas do Senado e Câmara - Legislapp';
    calOptions.url = 'https://legislapp.com.br';
    const actionSheet = await this.actionSheetController.create({
      cssClass: '',
      buttons: [
        {
          text: 'Adicionar ao calendário do dispositivo',
          icon: 'calendar-outline',
          handler: () => {
            this.calendar
              .createEventWithOptions(
                evento.title,
                evento.location,
                evento.description,
                new Date(evento.start_time),
                new Date(evento.end_time),
                calOptions
              )
              .then(function (arg) {
                toastController
                  .create({
                    message: 'Agenda adicionada ao calendário',
                    duration: 4000,
                  })
                  .then((toast) => toast.present());
              })
              .catch(function (arg) {
                alert('Função disponível apenas no aplicativo');
              });
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  verProjeto(idProjeto) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: idProjeto, IdUsuario: this.usuarioService.get().Id })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }

  transformUrlSafe(url) {
    if (url.indexOf('embed') >= 0)
      return this.sanitizer.bypassSecurityTrustResourceUrl(url.split(' ')[0]);
    url = 'https://www.youtube.com/embed/' + url.split('?v=')[1];
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  fechar() {
    this.modalController.dismiss({});
  }
}
