import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PipesModule } from './util/pipes.module';
import { AutoridadePageModule } from './autoridade/autoridade.module';
import { ContadorProposicaoModule } from './contador-proposicao/contador-proposicao.module';
import { HomePageModule } from './home/home.module';
import { IconeEmentaModule } from './icone-ementa/icone-ementa.module';
import { OrdemDiaPageModule } from './ordem-dia/ordem-dia.module';
import { PainelMenuPageModule } from './painel-menu/painel-menu.module';
import { PainelMenuPage } from './painel-menu/painel-menu.page';
import { ParlamentarPageModule } from './parlamentar/parlamentar.module';
import { PrimeiroAcessoPage } from './primeiro-acesso/primeiro-acesso.page';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { AssinaturaComponent } from './assinatura/assinatura.component';
import { AssinaturaModule } from './assinatura/assinatura.module';
// import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { RadarSlideModule } from './radar-slide/radar-slide.module';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { InterceptorService } from './api/interceptor.service';
import { GlobalErrorHandler } from './api/global-error-handler.service';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AgendaComponentPage } from './agenda-component/agenda-component.page';
import { AgendaComponentPageModule } from './agenda-component/agenda-component.module';
import { TypeaheadPage } from './typeahead/typeahead.page';
import { TypeaheadPageModule } from './typeahead/typeahead.module';
import { IconeInteiroTeorModule as IconeInteiroTeorModule } from './icone-inteiro-teor/icone-inteiro-teor.module';
import { SegmentoEstadosModule } from './segmento-estados/segmento-estados.module';
import { IconeCopiarModule } from './icone-copiar/icone-ementa.module';
import { StoryModule } from './story/story.module';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';

registerLocaleData(localePt);

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [AppComponent],
    imports: [BrowserModule, IonicModule, IonicModule.forRoot({ mode: 'ios', innerHTMLTemplatesEnabled: true }), FormsModule,
        AppRoutingModule, CommonModule, PipesModule, HttpClientModule, PainelMenuPageModule, ParlamentarPageModule, AutoridadePageModule, OrdemDiaPageModule, IconeEmentaModule, IconeCopiarModule, IconeInteiroTeorModule, HomePageModule, AssinaturaModule, RadarSlideModule, AgendaComponentPageModule, TypeaheadPageModule, SegmentoEstadosModule, StoryModule, PainelMenuPage, PrimeiroAcessoPage],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        HttpClient,
        OneSignal,
        GooglePlus,
        Vibration,
        Calendar,
        { provide: LOCALE_ID, useValue: 'pt' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
