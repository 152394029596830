import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { HomePageRoutingModule } from './home-routing.module';

import { HomePage } from './home.page';
import { IconeEmentaComponent } from '../icone-ementa/icone-ementa.component';
import { EmentaComponent } from '../ementa/ementa.component';
import { IconeEmentaModule } from '../icone-ementa/icone-ementa.module';
import { EmentaModule } from '../ementa/ementa.module';
import { ContadorProposicaoModule } from '../contador-proposicao/contador-proposicao.module';
import { DiarioOficialUsuarioModule } from '../diario-oficial-usuario/diario-oficial-usuario.module';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { RadarEdicaoPageModule } from '../radar-edicao/radar-edicao.module';
import { AgendaComponentPageModule } from '../agenda-component/agenda-component.module';
import { TypeaheadPageModule } from '../typeahead/typeahead.module';
import { AgendaExecutivoComponentPage } from '../agenda-executivo-component/agenda-executivo-component.page';
import { AgendaExecutivoComponentPageModule } from '../agenda-executivo-component/agenda-executivo-component.module';
import { SegmentoEstadosModule } from '../segmento-estados/segmento-estados.module';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HomePageRoutingModule,
    IconeEmentaModule,
    ContadorProposicaoModule,
    DiarioOficialUsuarioModule,
    RadarEdicaoPageModule,
    Ng2GoogleChartsModule,
    AgendaComponentPageModule,
    AgendaExecutivoComponentPageModule,
    TypeaheadPageModule,
    SegmentoEstadosModule
  ],
  declarations: [HomePage],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class HomePageModule {}
