// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chip-pesquisa-selecionado {
  background-color: #148b7f;
  color: white;
}

.align-center-item {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center !important;
  align-items: center !important;
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/app/agenda-component/agenda-component.page.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/agenda-component/agenda-component.page.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,YAAA;ACCF;;ADCA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;EACA,8BAAA;EACA,sBAAA;ACEF","sourcesContent":[".chip-pesquisa-selecionado {\r\n  background-color: #148b7f;\r\n  color: white;\r\n}\r\n.align-center-item {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  align-content: center !important;\r\n  align-items: center !important;\r\n  vertical-align: middle;\r\n}\r\n",".chip-pesquisa-selecionado {\n  background-color: #148b7f;\n  color: white;\n}\n\n.align-center-item {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-content: center !important;\n  align-items: center !important;\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
