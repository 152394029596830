import { Component, NgZone, OnInit } from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { UsuarioService } from '../api/usuario.service';
import { RadarEdicaoPage } from '../radar-edicao/radar-edicao.page';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';
import { AssinaturaService } from '../api/assinatura.service';
import { Clipboard } from '@capacitor/clipboard';
import { SelecaoUsuarioPlanoPage } from '../selecao-usuario-plano/selecao-usuario-plano.page';
import { ProjetoService } from '../api/projeto.service';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { ClienteService } from '../api/cliente-service.service';
import { NewsletterService } from '../api/newsletter.service';
import { DiarioOficialService } from '../api/diario-oficial.service';
import { RadarDiarioEdicaoPage } from '../radar-diario-edicao/radar-diario-edicao.page';
import { RadarDiarioMonitoramentoService } from '../api/radar-diario-monitoramento.service';

@Component({
  selector: 'app-radar-monitoramento',
  templateUrl: './radar-monitoramento.page.html',
  styleUrls: ['./radar-monitoramento.page.scss'],
})
export class RadarMonitoramentoPage extends PageBaseService implements OnInit {
  listaRadar: any;
  listaRadarBkp: any;
  qtdDiario = 0;
  qtdLegislativo = 0;
  totalProposicoes = 0;
  android: boolean;
  plano: any;
  segmento = 'dados';
  clienteLista: any;
  clienteSelecionado: any;
  listaRadarSelecionado = [];
  constructor(
    public actionSheetController: ActionSheetController,
    protected platform: Platform,
    private menuService: MenuService,
    private usuarioService: UsuarioService,
    private radarDiarioMonitoramentoService: RadarDiarioMonitoramentoService,
    private modalController: ModalController,
    private radarMonitoramentoService: RadarMonitoramentoService,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    private zone: NgZone,
    private projetoService: ProjetoService,
    private assinaturaService: AssinaturaService,
    private toastController: ToastController,
    private vibration: Vibration,
    private clienteService: ClienteService,
    private diarioOficialService: DiarioOficialService,
    private newsletterService: NewsletterService
  ) {
    super(loadingController, alertCtrl, null, null);
  }

  ngOnInit() {
    this.android = this.platform.is('android');
    this.usuarioService
      .obterPlano(this.usuarioService.get().Id)
      .then((data) => {
        this.plano = data;
        if (!this.plano) this.assinaturaService.assinar();
      });
    this.carregarLista();
    this.carregarListaCliente();
    setInterval(() => {
      if (localStorage.getItem('RADAR_MODIFICADO')) {
        localStorage.removeItem('RADAR_MODIFICADO');
        this.carregarLista();
      }
    }, 5000);
  }

  radarSelecionado(radar) {
    return this.listaRadarSelecionado.some((x) => x.Id === radar.Id);
  }

  segmentoChange($event) {
    if ($event) {
      this.segmento = $event.detail.value;
      if (this.segmento == 'dou') {
        this.totalProposicoes = 0;
        this.listaRadar = this.listaRadarBkp.filter((x) => x.DOU);
      } else {
        this.listaRadar = this.listaRadarBkp.filter((x) => !x.DOU);
      }
      this.listaRadar.map(
        (x) => (this.totalProposicoes = this.totalProposicoes + x.Tags.length)
      );
    }
  }
  compareWith(o1: any, o2: any) {
    if (!o1 || !o2) {
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      return o2.some((o) => o === o1);
    }

    return o1 === o2;
  }
  limparFiltroCliente() {
    this.clienteSelecionado = undefined;
    this.listaRadar = this.listaRadarBkp;
  }

  filtrarPorCliente(ev: any) {
    this.listaRadar = this.listaRadarBkp.filter((x) =>
      x.ClienteLista?.map((x) => x.Id).some(
        (o) => ev.target.value.indexOf(o) > -1
      )
    );
  }
  carregarListaCliente() {
    this.clienteService
      .listarPorUsuarioAdmin(this.usuarioService.get().Id)
      .then((data: any) => {
        this.clienteLista = data;
      });
  }

  // configuracaoClick(habilitado, tipoPush) {
  //   this.exibirLoading('Carregando...');
  //   this.configuracaoNotificacaoService.alterar({ Habilitado: habilitado, TipoPush: tipoPush, IdUsuario: this.usuarioService.get().Id }).then(data => {
  //     this.listaRadar = data as any[];
  //     this.ocultarLoading();
  //   });
  // }

  fechar() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({});
  }

  radarFocus(radar) {}

  radarBlur(radar) {}

  async criarLink(model) {
    model.Tipo = 'RADAR';
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header: 'Compartilhe seu Radar de Monitoramento',
      subHeader:
        'Digite o convite que aparecerá para quem acessar o link para acompanhamento das proposições do seu radar',
      inputs: [
        {
          name: 'convite',
          type: 'text',
          placeholder: 'Texto do seu convite (opcional)',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (convite) => {
            model.Descricao =
              convite.convite || 'Dá uma olhada no meu radar ' + model.Titulo;
            this.radarMonitoramentoService
              .criarLink(model)
              .then(async (link) => {
                await Clipboard.write({
                  string: link as string,
                });
                const alertComponent2 = await this.alertCtrl.create({
                  cssClass: 'modal-pontuacao',
                  header: 'Link de compartilhamento de Radar',
                  subHeader:
                    'Gerado com sucesso, o link foi automaticamente copiado para sua área de transferência',
                  buttons: [
                    {
                      text: 'Ok',
                      handler: (convite) => {},
                    },
                  ],
                });
                alertComponent2.present();
              });
          },
        },
      ],
    });
    alertComponent.present();
    this.ocultarLoading();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      position: 'bottom',
      color: 'primary',
      cssClass: 'toast-radar',
    });
    toast.onDidDismiss().then((e) => {});
    toast.present();
  }

  async radarClick(radar) {
    if (radar.DOU) {
      this.radarDiarioClick(radar);
    } else this.radarLegislativoClick(radar);
  }
  async radarDiarioClick(radar) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Monitoramento Diários Oficiais',
      cssClass: '',
      buttons: [
        // {
        //   text: 'Selecionar/Desmarcar Radar',
        //   icon: 'checkmark-circle-outline',
        //   handler: () => {
        //     if (!this.listaRadarSelecionado.some((x) => x.Id === radar.Id)) {
        //       this.listaRadarSelecionado.push(radar);
        //     } else {
        //       this.listaRadarSelecionado = this.listaRadarSelecionado.filter(
        //         (x) => x.Id !== radar.Id
        //       );
        //     }
        //   },
        // },
        {
          text: 'Ver/Editar monitoramento',
          icon: 'pencil-outline',
          handler: async () => {
            this.abrirCadastroRadarDiario(radar);
          },
        },
        {
          text: 'Exportar radares em Word',
          icon: 'cloud-download-outline',
          handler: async () => {
            this.exportarRadarDiarioWord(radar.Id);
          },
        },
        {
          text: 'Exportar radares em PDF',
          icon: 'cloud-download-outline',
          handler: async () => {
            this.exportarRadarDiarioPDF(radar.Id);
          },
        },
        {
          text: 'Deletar',
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.exibirLoading('Removendo radar...');
            var func = this.radarDiarioMonitoramentoService.deletar(radar.Id);
            func.then(async () => {
              this.listaRadar = this.listaRadar.filter((x) => x.Id != radar.Id);
              localStorage.setItem('RADAR_MODIFICADO', 'true');
              this.vibration.vibrate(2000);
              const toast = await this.toastController.create({
                message: 'Radar removido com sucesso!',
                duration: 3000,
                color: 'danger',
              });
              toast.present();
              localStorage.setItem('RADAR_MODIFICADO', 'true');
              this.ocultarLoading();
            });
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  exportarRadarDiarioPDF(id) {
    this.radarDiarioMonitoramentoService
      .gerarPDF(id, this.usuarioService.get().Id)
      .then((data: any) => {
        this.vibration.vibrate([2000, 1000, 1000]);
        this.presentToast(
          '🗞️ Exportando em PDF... te avisaremos quando estiver pronto para download.'
        );
      });
  }
  exportarRadarDiarioWord(id) {
    this.radarDiarioMonitoramentoService
      .gerarWord(id, this.usuarioService.get().Id)
      .then((data: any) => {
        this.vibration.vibrate([2000, 1000, 1000]);
        this.presentToast(
          '🗞️ Exportando em Word... te avisaremos quando estiver pronto para download.'
        );
      });
  }
  exportarRadaresWord() {
    this.radarMonitoramentoService
      .gerarWordLista(
        this.listaRadarSelecionado.map((x) => x.Id),
        this.usuarioService.get().Id
      )
      .then((data: any[]) => {
        this.vibration.vibrate([2000, 1000, 1000]);
        this.presentToast(
          '🗞️ Exportando o arquivo word... te avisaremos quando estiver pronto para download.'
        );
        this.listaRadarSelecionado = [];
      });
  }
  exportarRadaresPDF() {
    throw new Error('Method not implemented.');
  }
  exportarRadaresJSON() {
    throw new Error('Method not implemented.');
  }
  async radarLegislativoClick(radar) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Radar ' + radar.Titulo,
      cssClass: '',
      buttons: [
        {
          text: 'Selecionar/Desmarcar Radar',
          icon: 'checkmark-circle-outline',
          handler: () => {
            if (!this.listaRadarSelecionado.some((x) => x.Id === radar.Id)) {
              this.listaRadarSelecionado.push(radar);
            } else {
              this.listaRadarSelecionado = this.listaRadarSelecionado.filter(
                (x) => x.Id !== radar.Id
              );
            }
          },
        },
        {
          text: 'Ver Radar e Proposições',
          icon: 'eye-outline',
          handler: () => {
            this.cadastroLegislativoClick(radar);
          },
        },
        {
          text: 'Editar Radar',
          icon: 'pencil',
          handler: () => {
            this.cadastroLegislativoClick(radar);
          },
        },
        {
          text: 'Link de ingresso',
          icon: 'link-outline',
          handler: () => {
            this.criarLink(radar);
          },
        },
        {
          text: 'Adicionar Usuários',
          icon: 'person-add-outline',
          handler: async () => {
            const modal = await this.modalController.create({
              component: SelecaoUsuarioPlanoPage,
              cssClass: 'modal-pontuacao',
              componentProps: {
                radar,
              },
            });
            modal.present();
          },
        },
        {
          text: 'Exportar proposições em Excel',
          icon: 'cloud-download-outline',
          handler: async () => {
            this.radarMonitoramentoService
              .excel(radar.Id, this.usuarioService.get().Id)
              .then((data: any[]) => {
                this.presentToast(
                  '🗞️ Exportando o excel... te avisaremos quando estiver pronto para download.'
                );
              });
          },
        },
        {
          text: 'Exportar proposições em Word',
          icon: 'cloud-download-outline',
          handler: async () => {
            this.radarMonitoramentoService
              .gerarWord(radar.Id, this.usuarioService.get().Id)
              .then((data: any[]) => {
                this.presentToast(
                  '🗞️ Exportando o arquivo .DOC, te avisaremos quando estiver pronto para download.'
                );
              });
          },
        },
        {
          text: 'Exportar newsletter de proposições em PDF',
          icon: 'cloud-download-outline',
          handler: async () => {
            this.baixarPDF(radar.Id);
          },
        },
        {
          text: 'Exportar listagem de proposições em PDF',
          icon: 'cloud-download-outline',
          handler: async () => {
            this.gerarTabelaProjetoLeiJob(radar.Id);
          },
        },
        // {
        //   text: 'Exportar diários oficiais em Excel',
        //   icon: 'cloud-download-outline',
        //   handler: async () => {
        //     this.exportarDiarioExcel(radar.Id);
        //   },
        // },
        {
          text: 'Deletar',
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.exibirLoading('Removendo radar...');
            var func = !radar.DOU
              ? this.radarMonitoramentoService.deletar(radar.Id)
              : this.radarDiarioMonitoramentoService.deletar(radar.Id);
            func.then(async () => {
              this.listaRadar = this.listaRadar.filter((x) => x.Id != radar.Id);
              localStorage.setItem('RADAR_MODIFICADO', 'true');
              this.vibration.vibrate(2000);
              const toast = await this.toastController.create({
                message: 'Radar removido com sucesso!',
                duration: 3000,
                color: 'danger',
              });
              toast.present();
              localStorage.setItem('RADAR_MODIFICADO', 'true');
              this.ocultarLoading();
            });
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }

  exportarDiarioExcel(id) {
    this.exibirLoading('Exportando em Excel...');
    var that = this;
    setTimeout(() => {
      that.ocultarLoading();
    }, 10000);
    this.diarioOficialService.excel(id).then((data: any) => {
      this.ocultarLoading();
      if (data) window.open(data, '_system');
    });
  }
  baixarPDF(id) {
    this.exibirLoading(
      'Exportando em PDF... te avisaremos quando estiver pronto para download.'
    );
    this.vibration.vibrate([2000, 1000, 1000]);
    var that = this;
    setTimeout(() => {
      that.ocultarLoading();
    }, 5000);
    this.radarMonitoramentoService
      .gerarPDF(id, this.usuarioService.get().Id)
      .then((data: any) => {
        setTimeout(() => {
          that.ocultarLoading();
        }, 5000);
        this.presentToast(
          '🗞️ Exportando em PDF... te avisaremos quando estiver pronto para download....'
        );
      });
  }
  gerarTabelaProjetoLeiJob(id) {
    this.exibirLoading(
      'Exportando em PDF... te avisaremos quando estiver pronto para download.'
    );
    this.vibration.vibrate([2000, 1000, 1000]);
    var that = this;
    setTimeout(() => {
      that.ocultarLoading();
    }, 5000);
    this.newsletterService
      .gerarTabelaProjetoLeiJob(id, this.usuarioService.get().Id)
      .then((data: any) => {
        setTimeout(() => {
          that.ocultarLoading();
        }, 5000);
        this.presentToast(
          '🗞️ Exportando em PDF... te avisaremos quando estiver pronto para download....'
        );
      });
  }

  async abrirCadastroRadarLegislativo(radar) {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: RadarEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        radar,
      },
    });
    this.ocultarLoading();
    modal.onDidDismiss().then(async () => {
      modal.remove();
      const modal2 = await this.modalController.create({
        component: RadarMonitoramentoPage,
        cssClass: 'modal-pontuacao',
      });
      modal2.present();
    });
    return await modal.present();
  }
  async abrirCadastroRadarDiario(radar) {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: RadarDiarioEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        radar,
      },
    });
    this.ocultarLoading();
    modal.onDidDismiss().then(async () => {
      modal.remove();
      const modal2 = await this.modalController.create({
        component: RadarMonitoramentoPage,
        cssClass: 'modal-pontuacao',
      });
      modal2.present();
    });
    return await modal.present();
  }
  async cadastroButtonClick() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Escolha o tipo do monitoramento',
      cssClass: '',
      buttons: [
        {
          text: 'Cadastrar monitoramento legislativo',
          icon: 'person-outline',
          handler: () => {
            this.cadastroLegislativoClick({});
          },
        },
        {
          text: 'Cadastrar monitoramento de diários oficiais',
          icon: 'newspaper-outline',
          handler: () => {
            this.cadastroDiarioClick({});
          },
        },
      ],
    });
    await actionSheet.present();
  }
  async cadastroLegislativoClick(radar) {
    try {
      this.zone.run(() => {
        this.usuarioService
          .obterPlano(this.usuarioService.get().Id)
          .then((data) => {
            this.plano = data;
            if (!this.plano) this.assinaturaService.assinar();
          });
      });
    } catch (error) {}
    this.abrirCadastroRadarLegislativo(radar);
  }
  async cadastroDiarioClick(radar) {
    try {
      this.zone.run(() => {
        this.usuarioService
          .obterPlano(this.usuarioService.get().Id)
          .then((data) => {
            this.plano = data;
            if (!this.plano) this.assinaturaService.assinar();
          });
      });
    } catch (error) {}
    this.abrirCadastroRadarDiario(radar);
  }
  contemLocal(radar, local) {
    if (!radar.FiltroLocalLista) {
      return radar[local];
    }
    return (
      radar.FiltroLocalLista.filter((item) => item.Nome == local).length > 0
    );
  }
  carregarLista() {
    this.totalProposicoes = 0;
    // this.exibirLoading('Carregando...');
    this.radarMonitoramentoService
      .listar(this.usuarioService.get().Id)
      .then((data) => {
        this.listaRadar = data as any[];
        this.qtdLegislativo = this.listaRadar.length;
        this.listaRadarBkp = this.listaRadar;
        var that = this;
        this.radarDiarioMonitoramentoService
          .listar(this.usuarioService.get().Id)
          .then((data: any) => {
            that.qtdDiario = data.length;
            that.listaRadar = that.listaRadar.concat(data);
            that.listaRadar.sort((a, b) => {
              return a.Titulo > b.Titulo ? 1 : -1;
            });
            that.listaRadarBkp = that.listaRadar;
          });
        setTimeout(() => {
          if (that.listaRadar.length == 0) {
          } else {
            that.listaRadar.map(
              (x) =>
                (that.totalProposicoes = that.totalProposicoes + x.Tags.length)
            );
          }
        }, 200);

        this.ocultarLoading();
      });
  }
}
