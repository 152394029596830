import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampoEdicaoComponent } from './campo-edicao.component';
import { TypeaheadPageModule } from '../typeahead/typeahead.module';
import { QuillModule } from 'ngx-quill';


@NgModule({
    imports: [
        CommonModule,
        TypeaheadPageModule,
        QuillModule.forRoot({
            modules: {
                syntax: true
            }
        }),
        CampoEdicaoComponent,
    ],
    exports: [
        CampoEdicaoComponent
    ]
})
export class CampoEdicaoModule { }
