import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuarioService } from '../api/usuario.service';
import { LoadingController, AlertController, Platform, IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { PageBaseService } from '../util/page-base.service';
import { PushService } from '../api/push.service';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { Capacitor } from '@capacitor/core';
import { AssinaturaService } from '../api/assinatura.service';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-primeiro-acesso',
    templateUrl: './primeiro-acesso.page.html',
    styleUrls: ['./primeiro-acesso.page.scss'],
    imports: [
        IonicModule,
        NgIf,
        FormsModule,
    ],
})
export class PrimeiroAcessoPage extends PageBaseService implements OnInit {
  loading: HTMLIonLoadingElement;
  usuarioModel = {
    Id: null,
    Nome: null,
    Senha: '',
    TipoOauth: '',
    Email: '',
    Celular: '',
    ImagemPerfil: '',
    codigoVerificacao: 0,
    PushToken: '',
    SistemaOperacional: 'ANDROID',
  };
  codigoEnviado = false;
  carregando = false;
  tecladoVisivel = false;
  subscription: any;
  user: any;
  showAppleSignIn: boolean;
  @ViewChild('senha', { static: true }) senha;
  pwa = false;
  redefinirSenha: boolean;
  usuarioEsqueciSenha: any;
  idConvite: string;

  constructor(
    // private signInWithApple: SignInWithApple,
    private usuarioService: UsuarioService,
    private pushService: PushService,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    protected assinaturaService: AssinaturaService,
    protected router: Router,
    public platform: Platform,
    // private geolocationService: GeolocationService,
    public alertController: AlertController,
    private gplus: GooglePlus
  ) {
    super(loadingController, alertCtrl, platform, router);
  }

  ngOnInit() {
    if (window.location.href.indexOf('linkedin') > -1) {
      var email = window.location.href.split('=')[1];
      this.usuarioModel.Nome = 'Linkedin';
      this.usuarioModel.Email = email;
      this.usuarioModel.TipoOauth = 'GOOGLE';
      this.login();
    }
    if (!Capacitor.isNativePlatform()) {
      this.pwa = true;
      if (localStorage.getItem('USUARIO_PWA_GOOGLE')) {
        var usuarioPWA = JSON.parse(localStorage.getItem('USUARIO_PWA_GOOGLE'));
        this.usuarioModel.Nome = usuarioPWA.Nome;
        this.usuarioModel.Email = usuarioPWA.Email;
        this.usuarioModel.TipoOauth = 'GOOGLE';
        localStorage.removeItem('USUARIO_PWA_GOOGLE');
        this.login();
      }
      if (window.location.href.indexOf('redefinirsenha') > -1) {
        var token =
          window.location.href.split('/')[
            window.location.href.split('/').length - 1
          ];
        this.usuarioEsqueciSenha = {};
        this.usuarioEsqueciSenha.IdEncoded = token;
        this.redefinirSenha = true;
      }
      if (window.location.href.indexOf('ingressarplano') > -1) {
        var idConvite =
          window.location.href.split('/')[
            window.location.href.split('/').length - 1
          ];
        this.idConvite = idConvite;
      }

      this.user = undefined;
      this.showAppleSignIn = this.platform.is('ios');
      if (this.platform.is('android') && Capacitor.isNativePlatform()) {
        // this.consentimentoGeolocalizacao();
      }
    }
  }

  alterarSenha() {
    this.usuarioService
      .atualizarSenha(this.usuarioEsqueciSenha)
      .then((data: any) => {
        this.usuarioModel = data;
        this.usuarioModel.Senha = this.usuarioEsqueciSenha.Senha;
        this.login();
      });
  }

  async nativeGoogleLogin() {
    try {
      const gplusUser = await this.gplus
        .login({
          webClientId: '',
          offline: true,
          scopes: 'profile email',
        })
        .then((usuarioGoogle) => {
          this.usuarioModel.Nome = usuarioGoogle.displayName;
          this.usuarioModel.Email = usuarioGoogle.email;
          this.usuarioModel.ImagemPerfil = usuarioGoogle.imageUrl;
          this.usuarioModel.TipoOauth = 'GOOGLE';
          this.login();
        })
        .catch((err) => alert(err));
    } catch (err) {
      alert(err);
    }
  }

  // async consentimentoGeolocalizacao() {
  //   this.geolocationService
  //     .getCoordenadas()
  //     .then((resp) => { })
  //     .catch((error) => {
  //       //window.alert('Ocorreu um erro ao obter a geolocalização: ' + JSON.stringify(error));
  //     });
  // }

  async exibirErro(mensagem) {
    const alert = await this.alertCtrl.create({
      header: 'Aviso',
      message: mensagem,
      buttons: ['OK'],
    });
    alert.present();
  }

  codigoChange(index) {
    // switch (index) {
    //   case 1: this.codigo2.setFocus(); this.keyboard.show(); break;
    //   case 2: this.codigo3.setFocus(); this.keyboard.show(); break;
    //   case 3: this.codigo4.setFocus(); this.keyboard.show(); break;
    //   case 4: this.keyboard.hide(); break;
    //   default:
    //     break;
    // }
  }

  emailValido() {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(this.usuarioModel.Email).toLowerCase());
  }

  getUserDetail(userid: any) {
    // this.fb
    //   .api('/' + userid + '/?fields=id,email,name,picture', ['public_profile'])
    //   .then((res) => {
    //     console.log(res);
    //     this.user = res;
    //     this.usuarioModel.Email = this.user.email;
    //     this.usuarioModel.TipoOauth = 'Facebook';
    //     this.usuarioModel.ImagemPerfil = this.user.picture.data.url;
    //     this.usuarioModel.Nome = this.user.name;
    //     this.login();
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }
  fbLogin() {
    try {
      // this.fb
      //   .login(['public_profile', 'email'])
      //   .then((res) => {
      //     if (res.status === 'connected') {
      //       this.getUserDetail(res.authResponse.userID);
      //     } else {
      //       alert(
      //         'Por favor autorize o app Legislapp na sua conta do Facebook.'
      //       );
      //     }
      //   })
      //   .catch((e) => alert('Error logging into Facebook ' + e));
    } catch (error) {
      alert(error);
    }
  }
  googleLogin() {
    this.nativeGoogleLogin();
  }
  linkedinLogin() {
    window.location.href =
      'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77zkvufpncrumx&redirect_uri=https://api.legislapp.com.br/usuario/linkedin&state=foobar&scope=openid,profile,r_emailaddress,email,w_member_social,r_liteprofile';
  }
  appleLogin() {
    // var that = this;
    // this.signInWithApple
    //   .signin({
    //     requestedScopes: [
    //       ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
    //       ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
    //     ],
    //   })
    //   .then((res: AppleSignInResponse) => {
    //     var usuarioModel2 = { Email: '', TipoOauth: '', Nome: '', Id: '' };
    //     const decoded: any = jwt_decode(res.identityToken);
    //     usuarioModel2.Email = decoded.email;
    //     usuarioModel2.TipoOauth = 'Facebook';
    //     usuarioModel2.Nome =
    //       res.fullName.familyName + ' ' + res.fullName.familyName;
    //     that.usuarioService.login(usuarioModel2).then((response: any) => {
    //       if (response === false) {
    //         this.exibirErro(response);
    //         this.usuarioModel.Senha = '';
    //       } else {
    //         usuarioModel2.Id = response.Id;
    //         localStorage.setItem('USUARIO', JSON.stringify(usuarioModel2));
    //         localStorage.setItem('PRIMEIRO_ACESSO', 'true');
    //         this.router.navigateByUrl('/');
    //       }
    //       // setTimeout(() => {
    //       //   this.loading.dismiss();
    //       // }, 500);
    //     });
    //   });
  }

  emailClick() {
    var that = this;
    this.usuarioService
      .emailCadastrado(this.usuarioModel)
      .then((response: any) => {
        if (response) {
          this.usuarioModel = response;
          var that = this;
          setTimeout(() => {
            that.senha.setFocus();
          }, 1000);
        } else {
          this.router.navigateByUrl('/cadastro/' + this.usuarioModel.Email);
        }
      });
  }
  inputFocus() {
    this.tecladoVisivel = true;
  }
  voltar() {
    this.usuarioModel.Id = undefined;
  }

  inputBlur() {
    setTimeout(() => {
      this.tecladoVisivel = false;
    }, 100);
  }
  login() {
    this.carregando = true;
    var that = this;
    setTimeout(() => {
      that.loading.dismiss();
    }, 1000);
    this.usuarioService.login(this.usuarioModel).then((response: any) => {
      if (typeof response == 'string') {
        this.exibirErro(response);
        this.carregando = false;
      } else {
        this.usuarioModel.Senha = '';
        this.usuarioModel.Id = response.Id;
        this.usuarioModel.Nome = response.Nome;
        this.usuarioModel.ImagemPerfil = response.ImagemPerfil;
        this.usuarioService.set(this.usuarioModel);
        if (localStorage.getItem('REDIRECT_URL')) {
          var url = localStorage.getItem('REDIRECT_URL');
          localStorage.removeItem('REDIRECT_URL');
          window.location.href = url;
          window.location.reload();
          return;
        }
        if (this.idConvite) {
          this.usuarioService
            .ingressarConvite({
              IdUsuario: this.usuarioModel.Id,
              IdConvite: this.idConvite,
            })
            .then(() => {
              this.assinaturaService.exibirSucessoAssinatura();
              localStorage.setItem('PRIMEIRO_ACESSO', 'true');
              window.location.href = '/';
            });
        } else {
          localStorage.setItem('PRIMEIRO_ACESSO', 'true');
          window.location.href = '/';
        }
      }
      // setTimeout(() => {
      //   this.loading.dismiss();
      // }, 500);
    });
  }
  emailKeyPress(code) {
    if (code == 13) this.emailClick();
  }
  senhaKeyPress(code) {
    if (code == 13) this.login();
  }
  prosseguirSemLoginClick() {
    this.usuarioModel.Nome = 'Não identificado';
    this.usuarioModel.Email = 'naoidentificado@naoidentificado.com';
    this.usuarioModel.Id = 1;
    this.usuarioService.set(this.usuarioModel);
    localStorage.setItem('PRIMEIRO_ACESSO', 'true');
    this.router.navigateByUrl('/');
  }

  cadastrar() {
    this.router.navigateByUrl('/cadastro');
  }

  async esqueciSenhaClick() {
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header:
        'Digite abaixo os dados para receber um e-mail com link para redifinição:',
      inputs: [
        {
          name: 'email',
          type: 'text',
          placeholder: 'Digite seu o e-mail cadastrado',
          value: this.usuarioModel.Email,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (data) => {
            this.exibirLoading('');
            this.usuarioService
              .esqueciSenha(data.email.toLowerCase())
              .then((result) => {
                this.loading.dismiss();
                alert(
                  'Foi enviado um e-mail com o link para redefinição da senha'
                );
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              });
          },
        },
      ],
    });
    alertComponent.present();
  }

  ionViewDidEnter() {
    this.subscription = this.platform.backButton.subscribe(() => {
      if (this.codigoEnviado) {
        this.codigoEnviado = false;
      } else {
        (navigator as any).app.exitApp();
      }
    });
  }
}
