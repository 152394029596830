import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssinaturaModule } from '../assinatura/assinatura.module';
import { RadarSlideComponent } from './radar-slide.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MeuPlanoPageModule } from '../meu-plano/meu-plano.module';
import { StoryModule } from '../story/story.module';



@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AssinaturaModule,
        MeuPlanoPageModule,
        StoryModule,
        RadarSlideComponent
    ],
})
export class RadarSlideModule { }
