import { Component, Input, OnInit } from '@angular/core';
import {
  LoadingController,
  AlertController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { ProjetoService } from '../api/projeto.service';
import { UsuarioService } from '../api/usuario.service';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { PageBaseService } from '../util/page-base.service';

@Component({
  selector: 'app-contador-proposicao',
  templateUrl: './contador-proposicao.component.html',
  styleUrls: ['./contador-proposicao.component.scss'],
})
export class ContadorProposicaoComponent implements OnInit {
  @Input()
  qtdDoc: any;
  @Input()
  qtdVotacao: any;
  @Input()
  qtdMovimentacao: any;
  @Input()
  qtdTwitter: any;
  @Input()
  idProjeto: any;
  constructor(
    protected modalController: ModalController,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    private projetoService: ProjetoService,
    private usuarioService: UsuarioService
  ) {}

  ngOnInit() {}

  verProjeto(aba) {
    this.abrirProjeto(aba);
  }

  abrirProjeto(aba) {
    this.projetoService
      .obter({
        Id: this.idProjeto,
        IdUsuario: this.usuarioService.get() ? this.usuarioService.get().Id : 0,
      })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
            aba,
          },
        });
        
        return await modal.present();
      });
  }
}
