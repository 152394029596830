import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { ParlamentarService } from '../api/parlamentar.service';
import { ProjetoService } from '../api/projeto.service';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { SessaoPlenarioService } from '../api/sessao-plenario.service';
import { MovimentacaoService } from '../api/movimentacao.service';
import { UsuarioService } from '../api/usuario.service';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';
import { ParlamentarComponent } from '../parlamentar/parlamentar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RadarEdicaoPage } from '../radar-edicao/radar-edicao.page';
import { VotoParlamentarPage } from '../voto-parlamentar/voto-parlamentar.page';
import { Clipboard } from '@capacitor/clipboard';
import { AssinaturaService } from '../api/assinatura.service';
import { Share } from '@capacitor/share';
import { GoogleChartInterface } from 'ng2-google-charts';
import { BuscaService } from '../api/busca.service';
import { Title } from '@angular/platform-browser';
import { TwitterService } from '../api/twitter.service';
import { DocumentoProjetoService } from '../api/documento-projeto.service';
import { DiscursoService } from '../api/discurso.service';
import { NoticiaService } from '../api/noticia.service';
import { OrdemDiaService } from '../api/ordem-dia.service';
import { CampoService } from '../api/campo.service';
import { EmentaComponent } from '../ementa/ementa.component';
import { TarefaEdicaoComponent } from '../tarefa-edicao/tarefa-edicao.component';
import { TarefaService } from '../api/tarefa.service';
import { CampoEdicaoComponent } from '../campo-edicao/campo-edicao.component';
@Component({
  selector: 'app-proposicao',
  templateUrl: './proposicao.page.html',
  styleUrls: ['./proposicao.page.scss'],
})
export class ProposicaoPage extends PageBaseService implements OnInit {
  @ViewChild('btnPresentPopover') btnPopover: ElementRef<HTMLElement>;
  @ViewChild('btnUploadDoc') btnUploadDoc: ElementRef<HTMLElement>;
  @Input()
  proposicao: any;
  @Input()
  aba: string;
  documentoLista: any[];
  tarefaLista: any[];
  documentoUsuarioLista: any[];
  movimentacaoLista: any[];
  segmento = 'dados';
  votoLista: any;
  listaTags: any[];
  radarCadastrado = false;
  tweetLista: any[];
  plano: any;
  private file: File;
  public columnChart4: GoogleChartInterface;
  public columnChart5: GoogleChartInterface;
  arrayEvolucao: any[][];
  arrayTempoOrgao: any[][];
  nativePlatform = true;
  discursoLista: any[];
  ordemDiaLista: any[];
  noticiaLista: any[];
  relacionadaLista: any[];
  somenteLeitura: any;
  constructor(
    protected platform: Platform,
    private route: ActivatedRoute,
    protected router: Router,
    private menuService: MenuService,
    private usuarioService: UsuarioService,
    private buscaService: BuscaService,
    private radarMonitoramentoService: RadarMonitoramentoService,
    private modalController: ModalController,
    private parlamentarService: ParlamentarService,
    private sessaoPlenarioService: SessaoPlenarioService,
    private movimentacaoService: MovimentacaoService,
    private projetoService: ProjetoService,
    private tarefaService: TarefaService,
    private documentoService: DocumentoProjetoService,
    private twitterService: TwitterService,
    private noticiaService: NoticiaService,
    private assinaturaService: AssinaturaService,
    private campoService: CampoService,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    private actionSheetController: ActionSheetController,
    private zone: NgZone,
    // private fileTransfer: FileTransfer,
    // private fileOpener: FileOpener,
    private titleService: Title,
    private assinatura: AssinaturaService,
    private discursoService: DiscursoService,
    private ordemDiaService: OrdemDiaService,
    private toastController: ToastController,
    public popoverController: PopoverController
  ) {
    super(loadingController, alertCtrl, platform, router);
  }
  ngOnInit() {
    if (this.usuarioService.get()) {
      this.usuarioService
        .permissao(this.usuarioService.get().Email)
        .then((data) => {
          this.somenteLeitura = data;
        });
    }
    var id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.nativePlatform = false;
      this.projetoService
        .obter({ Id: id }, (error) => {
          this.documentoService.obter(id).then((dataDoc: any) => {
            this.projetoService
              .obter({ Id: dataDoc.IdProjeto })
              .then((data) => {
                this.proposicao = data;
                this.carregar();
                this.segmentoChange({ detail: { value: 'documentos' } });
              });
          });
        })
        .then((data) => {
          this.proposicao = data;
          this.carregar();
        });
    } else {
      this.carregar();
    }
  }
  excel() {
    this.presentToast('Download iniciado, aguarde...');
    var idProjetoLista = [this.proposicao.Id];
    window.location.href = this.projetoService.excel({
      IdProjetoLista: idProjetoLista,
      Nome: this.proposicao.Titulo,
    });
  }
  pdf() {
    this.presentToast('Download iniciado, aguarde...');
    this.projetoService
      .pdf({
        IdProjeto: this.proposicao.Id,
        IdUsuario: this.usuarioService.get().Id,
      })
      .then((data: any) => {
        window.location.href = data;
      });
  }
  validarEscrita() {
    if (this.somenteLeitura) {
      alert('O seu perfil possui marcação de somente leitura');
      return false;
    }
    return true;
  }
  async tarefaClick(tarefa) {
    if (!this.validarEscrita()) return;
    const actionSheet = await this.actionSheetController.create({
      header: this.proposicao.Titulo + ' - ' + this.proposicao.CategoriaStr,
      cssClass: '',
      buttons: [
        {
          text: 'Remover',
          icon: 'trash-outline',
          handler: () => {
            this.tarefaService.remover(tarefa.Id).then(() => {});
            this.presentToast('Tarefa removida com sucesso!');
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  async menuClick() {
    const actionSheet = await this.actionSheetController.create({
      header: this.proposicao.Titulo + ' - ' + this.proposicao.CategoriaStr,
      cssClass: '',
      buttons: [
        {
          text: 'Monitorar',
          icon: 'notifications-outline',
          handler: () => {
            this.criarRadar();
          },
        },
        {
          text: 'Compartilhar/Copiar link',
          icon: 'share-outline',
          handler: () => {
            this.share('');
          },
        },
        {
          text: 'Exportar em PDF',
          icon: 'document-text-outline',
          handler: () => {
            this.pdf();
          },
        },
        {
          text: 'Exportar em excel',
          icon: 'cloud-download-outline',
          handler: () => {
            this.excel();
          },
        },
        {
          text: 'Criar novo campo',
          icon: 'add-circle-outline',
          handler: () => {
            if (!this.validarEscrita()) return;
            this.addCampo();
          },
        },
        {
          text: 'Anexar documento',
          icon: 'document-attach-outline',
          handler: () => {
            if (!this.validarEscrita()) return;
            this.btnUploadDoc.nativeElement.click();
          },
        },
        {
          text: 'Criar tarefa',
          icon: 'chatbubbles-outline',
          handler: () => {
            if (!this.validarEscrita()) return;
            this.criarTarefa();
          },
        },
        {
          text: 'Ver no site do legislativo',
          icon: 'open-outline',
          handler: () => {
            var url =
              this.proposicao.CategoriaStr == 'Senado'
                ? 'https://www25.senado.leg.br/web/atividade/materias/-/materia/' +
                  this.proposicao.IdProposicaoOrigem
                : 'https://www.camara.leg.br/proposicoesWeb/fichadetramitacao?idProposicao=' +
                  this.proposicao.IdProposicaoOrigem;
            window.open(url, '_system');
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  async criarTarefa() {
    const modal = await this.modalController.create({
      component: TarefaEdicaoComponent,
      cssClass: 'modal-pontuacao',
      componentProps: {
        idProjeto: this.proposicao.Id,
        recarregarCallback: this.carregarTarefas,
        scope: this,
      },
    });
    return await modal.present();
  }
  carregarCampos() {
    this.campoService
      .listarProjetoLei(this.usuarioService.get().Id, this.proposicao.Id)
      .then((data: any) => {
        if (data == null) data = { Schema: '{}' };
        this.proposicao.SchemaKeys = Object.keys(JSON.parse(data.Schema));
        this.proposicao.Schema = JSON.parse(data.Schema);
        this.proposicao.Campo = data;
      });
  }
  carregarTermometro() {
    this.projetoService.termometro(this.proposicao.Id).then((data: any) => {
      this.proposicao.Termometro = data.Percentual;
    });
  }
  onFileChange(fileChangeEvent) {
    this.presentToast('Enviando documento...');
    this.file = fileChangeEvent.target.files[0];
    let formData = new FormData();
    formData.append('files', this.file, this.file.name);
    this.documentoService
      .enviarDocumento(
        formData,
        this.usuarioService.get().Id,
        this.proposicao.Id
      )
      .then((data: any) => {
        this.presentToast('Upload finalizado!');
        this.carregarDocumentoUsuario();
      });
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: EmentaComponent,
      cssClass: 'modal-pontuacao',
      event: ev,
      componentProps: {
        ementa:
          '💡Monitore, Compartilhe, Adicione campos personalizados, Documentos e Baixe em Excel usando o Menu',
      },
    });
    await popover.present();
  }

  carregar() {
    var that = this;
    if (!localStorage.getItem('TUTORIAL_MENU_PROPOSICAO'))
      setTimeout(() => {
        that.btnPopover.nativeElement.click();
        localStorage.setItem('TUTORIAL_MENU_PROPOSICAO', 'true');
      }, 3000);
    this.titleService.setTitle(
      'Legislapp • ' + this.proposicao.Titulo + ' - ' + this.proposicao.Autoria
    );
    this.zone.run(() => {
      this.projetoService
        .listarRadar(
          this.proposicao.Id,
          this.usuarioService.get() ? this.usuarioService.get().Id : 0
        )
        .then((data) => {
          this.proposicao.RadarLista = data;
        });
      this.carregarTweets(this.proposicao.Id);
      this.carregarMovimentacoes();
      this.carregarPctChanceAprovacao();
      this.carregarVotacoes();
      this.carregarDocumentos();
      this.carregarTarefas();
      this.carregarDocumentoUsuario();
      this.carregarListaDiscurso([this.proposicao]);
      this.carregarListaOrdemDia(this.proposicao);
      this.carregarRelacionadas(this.proposicao);
      this.carregarListaNoticia([this.proposicao]);
      this.carregarCampos();
      this.carregarRelator();
      this.carregarTermometro();
    });
    var projetoVisualizado = {
      IdProjeto: this.proposicao.Id,
      Usuario: this.usuarioService.get().Email,
    };
    this.projetoService.visualizar(projetoVisualizado);
    if (this.aba) {
      this.segmentoChange({ detail: { value: this.aba } });
    }
  }
  async copy() {
    await Clipboard.write({
      string: 'https://app.legislapp.com.br/#/proposicao/' + this.proposicao.Id,
    });
    this.presentToast('Link copiado!');
  }
  fechar() {
    this.modalController.dismiss({});
  }

  carregarListaDiscurso(projetoLista: any[]) {
    this.discursoService
      .listarPorProjeto(projetoLista.map((x) => x.Id))
      .then((data: any[]) => {
        this.discursoLista = data;
      });
  }
  carregarListaOrdemDia(projeto: any) {
    this.ordemDiaService
      .listarPorTag(projeto.Titulo, null)
      .then((data: any[]) => {
        this.ordemDiaLista = data;
      });
  }

  carregarRelator() {
    if (this.proposicao.Relator != 'Sem relator') {
      this.parlamentarService
        .obterPorNome(this.proposicao.Relator)
        .then((data: any[]) => {
          this.proposicao.RelatorObj = data;
        });
    }
  }
  carregarRelacionadas(projeto: any) {
    this.projetoService.listarRelacionadas(projeto.Id).then((data: any[]) => {
      this.relacionadaLista = data;
    });
  }
  async proposicaoClick(proposicao) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({
        Id: proposicao.Id,
        IdUsuario: this.usuarioService.get() ? this.usuarioService.get().Id : 0,
      })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }
  carregarListaNoticia(projetoLista: any[]) {
    this.noticiaService
      .listarPorProjeto(projetoLista.map((x) => x.Id))
      .then((data: any[]) => {
        this.noticiaLista = data;
      });
  }

  fomatarSumario(sumario) {
    return sumario.replace('&nbsp;', '\r\n');
  }
  async addCampo() {
    const modal = await this.modalController.create({
      component: CampoEdicaoComponent,
      cssClass: 'modal-pontuacao',
      componentProps: {
        proposicao: this.proposicao,
        recarregarCallback: this.carregarCampos,
        scope: this,
      },
    });
    return await modal.present();
  }
  async editarCampo(chave) {
    const modal = await this.modalController.create({
      component: CampoEdicaoComponent,
      cssClass: 'modal-pontuacao',
      componentProps: {
        proposicao: this.proposicao,
        chave,
        recarregarCallback: this.carregarCampos,
        scope: this,
      },
    });
    return await modal.present();
  }
  async campoClick(campo) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Campo ' + campo,
      cssClass: '',
      buttons: [
        {
          text: 'Editar Valor',
          icon: 'pencil',
          handler: () => {
            this.editarCampo(campo);
          },
        },
        {
          text: 'Deletar',
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            delete this.proposicao.Schema[campo];
            this.campoService
              .alterar({
                Id: this.proposicao.Campo.Id,
                IdProjetoLei: this.proposicao.Id,
                IdUsuario: this.usuarioService.get().Id,
                Schema: JSON.stringify(this.proposicao.Schema),
              })
              .then((data: any[]) => {
                this.carregarCampos();
              });
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }

  async addValorCampo(nome) {
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header: 'Adicione campos customizados à matéria',
      subHeader:
        'Deseja adicionar algum valor inicial? Se quiser, você pode fazer isso depois',
      inputs: [
        {
          name: 'valor',
          type: 'text',
          placeholder: 'Valor do campo ' + nome,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (model) => {},
        },
      ],
    });
    alertComponent.present();
  }
  async votoClick(voto) {
    this.exibirLoading('Carregando...');
    this.sessaoPlenarioService.listarPorId(voto.Id).then(async (data) => {
      const modal = await this.modalController.create({
        component: VotoParlamentarPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          projetoSessaoPlenario: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  carregarProgresso() {
    this.movimentacaoService
      .ListarProgresso(this.proposicao.Id)
      .then((data) => {
        this.arrayEvolucao = (data as any[]).map((x) => [
          x.Key.toString(),
          x.Value as number,
        ]);
        this.arrayEvolucao.unshift(['Mês/Ano', 'Andamentos']);
        this.columnChart4 = {
          chartType: 'ScatterChart',
          dataTable: this.arrayEvolucao,
          options: {
            width: 900,
            height: 150,
            chartArea: { width: '800' },
            lineWidth: 1,
            legend: {
              position: 'left',
            },
          },
        };
      });
    this.movimentacaoService
      .listarTempoOrgao(this.proposicao.Id)
      .then((data) => {
        this.arrayTempoOrgao = (data as any[]).map((x) => [
          x.Orgao.toString(),
          x.Dias as number,
        ]);
        this.arrayTempoOrgao.unshift(['Dias', 'Dias no Orgão']);
        var width =
          (this.platform.width() as any) - (this.platform.width() as any) * 0.2;
        var chartWidth =
          (this.platform.width() as any) - (this.platform.width() as any) * 0.4;
        this.columnChart5 = {
          // use :any or :GoogleChartInterface
          chartType: 'BarChart',
          dataTable: this.arrayTempoOrgao,
          options: {
            width,
            chartArea: { width: chartWidth },
            titleTextStyle: {
              fontSize: 16, // 12, 18 whatever you want (don't specify px)
              bold: true, // true or false
            },
            vAxis: {
              textStyle: { color: '#069', fontSize: 10 },
            },
            hAxis: { textStyle: { color: '#069', fontSize: 10 } },
            bar: { groupWidth: '100%' },
            isStacked: true,
            legend: {
              position: 'top',
            },
          },
        };
      });
  }

  focusSegment(segmentId) {
    setTimeout(() => {
      this.zone.run(() => {
        document.getElementById(segmentId).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      });
    }, 1000);
  }
  carregarMovimentacoes() {
    this.carregarProgresso();
    this.movimentacaoService
      .listarPorProjeto(this.proposicao.Id)
      .then((data: any[]) => {
        this.movimentacaoLista = data;
      });
  }

  carregarPctChanceAprovacao() {
    this.projetoService
      .obterPctChanceAprovacao(this.proposicao.Id)
      .then((data) => {
        this.proposicao.PctChanceAprovacao = data;
      });
  }

  carregarDocumentos() {
    this.projetoService
      .listarDocumentos({ Id: this.proposicao.Id })
      .then((data) => {
        this.documentoLista = data as any[];
        this.documentoLista.map((x) => {
          x.Link = x.Link.replace('http:', 'https:');
          return x;
        });
        this.ocultarLoading();
      });
  }

  carregarTarefas(that = undefined) {
    if (!that) that = this;
    that.tarefaService
      .listar(that.usuarioService.get().Id, that.proposicao.Id, null, null)
      .then((data) => {
        that.tarefaLista = data as any[];
        that.ocultarLoading();
      });
  }

  carregarDocumentoUsuario() {
    this.documentoService
      .listarPorUsuario(this.usuarioService.get().Id, this.proposicao.Id)
      .then((data) => {
        this.documentoUsuarioLista = data as any[];
        this.ocultarLoading();
      });
  }

  carregarVotacoes() {
    this.sessaoPlenarioService
      .listarPorProjeto(this.proposicao.Id)
      .then((data) => {
        this.votoLista = data;
        this.ocultarLoading();
      });
  }
  segmentoChange($event) {
    if (!this.plano && this.usuarioService.get()) {
      this.usuarioService
        .obterPlano(this.usuarioService.get().Id)
        .then((plano) => {
          this.plano = plano;
          if (!this.plano) {
            this.assinaturaService.assinar();
          }
        });
    }

    if ($event) {
      this.segmento = $event.detail.value;
      if (
        this.segmento === 'twitter' &&
        this.tweetLista &&
        this.tweetLista.length > 0
      ) {
        if (!this.plano) {
          this.usuarioService
            .obterPlano(this.usuarioService.get().Id)
            .then((plano) => {
              this.plano = plano != null;
              if (!plano) {
                this.assinatura.assinar();
              }
            });
        }
        this.carregarHtmlTweets();
      }
      this.focusSegment(this.segmento);
    }
  }

  noticiaClick(noticia) {
    window.open(noticia.Url, '_system');
    if (!this.plano) {
      try {
        this.zone.run(() => {
          this.assinatura
            .inicializar(this.usuarioService.get().Id)
            .subscribe((data) => {
              this.plano = data;
              if (!this.plano) this.assinaturaService.assinar();
            });
        });
      } catch (error) {}
    }
  }
  onImgErrorNoticia(item) {
    item.UrlImagem =
      'https://legislapp.com.br/wp-content/uploads/Prancheta-289.png';
  }

  async tagClick(tag) {
    const modal = await this.modalController.create({
      component: RadarEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        tendencia: tag.replace('[', '').replace(']', ''),
      },
    });
    return await modal.present();
  }

  async verRadarClick(radar) {
    this.modalController.dismiss();
    this.radarMonitoramentoService
      .listar(this.usuarioService.get().Id)
      .then(async (data: any[]) => {
        radar = data.find((x) => x.Id == radar.Id);
        const modal = await this.modalController.create({
          component: RadarEdicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            radar,
          },
        });
        return await modal.present();
      });
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      position: 'bottom',
      color: 'primary',
      cssClass: 'toast-radar',
    });
    toast.onDidDismiss().then((e) => {});
    toast.present();
  }

  onImgError(item) {
    if (item.Autoria == 'Senado Federal') {
      item.UrlFotoAutor = '/assets/logo-senado.png';
    } else if (item.Autoria == 'Câmara dos Deputados') {
      item.UrlFotoAutor = '/assets/logo-camara.png';
    } else if (
      item.Autoria == 'Poder Executivo' ||
      item.Autoria == 'Presidência da República'
    ) {
      item.UrlFotoAutor = '/assets/poderexecutivo.png';
    } else if (item.Autoria.toUpperCase().indexOf('COMISSÃO') > -1) {
      item.UrlFotoAutor = '/assets/comissoes.png';
    } else {
      item.UrlFotoAutor = '/assets/imagem-perfil.jpg';
    }
  }

  async parlamentarClick(proposicao) {
    if (proposicao.IdAutor) {
      this.exibirLoading('Carregando...');
      this.parlamentarService
        .obter({ IdAutor: proposicao.IdAutor })
        .then(async (data) => {
          const modal = await this.modalController.create({
            component: ParlamentarComponent,
            cssClass: 'modal-pontuacao',
            componentProps: {
              parlamentar: data,
            },
          });
          this.ocultarLoading();
          return await modal.present();
        });
    }
  }
  async relatorClick() {
    this.exibirLoading('Carregando...');
    this.parlamentarService
      .obter({ Id: this.proposicao.RelatorObj.Id })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ParlamentarComponent,
          cssClass: 'modal-pontuacao',
          componentProps: {
            parlamentar: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }

  async criarRadar() {
    if (!this.validarEscrita()) return;
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header: 'Clique em OK para salvar o radar',
      inputs: [
        {
          name: 'nomeProjeto',
          type: 'text',
          placeholder: 'Ex: PL 9999/2021',
          value: this.proposicao.Titulo,
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (data) => {
            this.exibirLoading('Carregando...');
            this.radarMonitoramentoService
              .cadastrar({
                IdUsuario: this.usuarioService.get().Id,
                Autor: '',
                Tags: [],
                Camara: true,
                Senado: true,
                Titulo: data.nomeProjeto,
              })
              .then((data: any) => {
                this.radarMonitoramentoService
                  .InserirProjeto(data, this.proposicao.Id)
                  .then(() => {
                    this.ocultarLoading();
                    this.radarCadastrado = true;
                    this.presentToast(
                      '🚨 Monitoramento adicionado com sucesso'
                    );
                  });
              });
          },
        },
      ],
    });
    alertComponent.present();
    this.ocultarLoading();
  }

  documentoUsuarioClick(documento) {
    // this.exibirLoading('Baixando...');
    this.presentToast('📄 Download iniciado...');
    var url = documento.Link.replace('http:', 'https:');
    window.open(url, '_system');
  }

  documentoClick(documento) {
    // this.exibirLoading('Baixando...');
    this.presentToast('📄 Download iniciado...');

    if (documento.Link.indexOf('ple.cl.df') > -1) {
      var idProposicao = documento.Link.split('/')[7];
      var idDocumento = documento.Link.split('/')[9];
      var link =
        'https://ple.cl.df.gov.br/pleservico/api/public/proposicao/exportar/' +
        idProposicao +
        '/pdf';
      this.documentoService
        .downloadDocumentoClDf(link, [idDocumento])
        .then((data: any) => {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(data);
          link.download = documento.Tipo + '.pdf';
          link.click();
        });
    } else {
      var url = documento.Link.replace('http:', 'https:');
      window.open(url, '_system');
    }
  }
  redeSocialClick(url) {
    window.open(url, '_system');
  }

  share(destino) {
    var texto = `${
      this.proposicao.Titulo +
      ' do(a) ' +
      this.proposicao.Autoria +
      (this.proposicao.Partido || this.proposicao.UFAutor
        ? ' (' +
          (this.proposicao.Partido +
            (this.proposicao.UFAutor ? '/' + this.proposicao.UFAutor : '')) +
          ')'
        : '')
    }: ${this.proposicao.Ementa}`;
    var link = `https://app.legislapp.com.br/#/proposicao/${this.proposicao.Id}`;
    Share.share({
      title: 'Dá uma olhada nessa publicação',
      text: texto,
      url: link,
      dialogTitle: 'Compartilhe o legislativo onde quiser!',
    })
      .then((arg) => {})
      .catch((error) => {
        // alert(JSON.stringify(error));
        this.loading.dismiss();
      });
  }

  async criarLink() {
    const alertComponent = await this.alertCtrl.create({
      cssClass: 'modal-pontuacao',
      header: 'Compartilhe seu Radar de Monitoramento',
      subHeader:
        'Digite o convite que aparecerá para quem acessar o link para acompanhamento das proposições do seu radar',
      inputs: [
        {
          name: 'convite',
          type: 'text',
          placeholder: 'Texto do seu convite (opcional)',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Ok',
          handler: (convite) => {
            var model = {
              IdUsuario: this.usuarioService.get().Id,
              Autor: '',
              Tags: this.proposicao.Titulo,
              Camara: true,
              Senado: true,
              Titulo: this.proposicao.Titulo,
              Descricao: convite.convite || this.proposicao.Titulo,
              Tipo: 'PROPOSICAO_UNICA',
            };
            this.radarMonitoramentoService
              .criarLink(model)
              .then(async (link) => {
                await Clipboard.write({
                  string: link as string,
                });
                const alertComponent2 = await this.alertCtrl.create({
                  cssClass: 'modal-pontuacao',
                  header: 'Link de compartilhamento de Radar',
                  subHeader:
                    'Gerado com sucesso, o link foi automaticamente copiado para sua área de transferência',
                  buttons: [
                    {
                      text: 'Ok',
                      handler: (convite) => {},
                    },
                  ],
                });
                alertComponent2.present();
              });
          },
        },
      ],
    });
    alertComponent.present();
    this.ocultarLoading();
  }
  carregarHtmlTweets() {
    var size = 20 > this.tweetLista.length ? this.tweetLista.length : 20;
    var that = this;
    this.zone.run(() => {
      this.tweetLista.slice(0, size).forEach(function (e, index) {
        that.parlamentarService.obterTwitterHtml(e).then((html) => {
          that.tweetLista[index].html = html;
          if (index == size - 1) {
            setTimeout(() => {
              (window as any).twttr.widgets.load();
              that.ocultarLoading();
            }, 1000);
          }
        });
      });
    });
  }
  carregarTweets(idProjeto) {
    this.twitterService.buscar([idProjeto], []).then((data) => {
      this.tweetLista = data as any[];
      this.carregarHtmlTweets();
      if (this.aba) {
        this.segmentoChange({ detail: { value: this.aba } });
      }
    });
  }
}
