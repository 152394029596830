import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SegmentoEstadosComponent } from './segmento-estados.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  exports:[
    SegmentoEstadosComponent
  ],
  declarations: [SegmentoEstadosComponent]
})
export class SegmentoEstadosModule {}