import { Component, ElementRef, ViewChild } from '@angular/core';
import { RadarMonitoramentoPage } from '../radar-monitoramento/radar-monitoramento.page';

import { Platform, LoadingController, NavController, AlertController, ModalController, IonSearchbar, ToastController, IonicModule } from '@ionic/angular';
import { RadarEdicaoPage } from '../radar-edicao/radar-edicao.page';
import { PageBaseService } from '../util/page-base.service';
import { AssinaturaService } from '../api/assinatura.service';
import { UsuarioService } from '../api/usuario.service';
import { Capacitor } from '@capacitor/core';
import { MeuPlanoPage } from '../meu-plano/meu-plano.page';
import { ConfiguracaoNotificacaoPage } from '../configuracao-notificacao/configuracao-notificacao.page';
import { NewsletterListaPage } from '../newsletter-lista/newsletter-lista.page';

@Component({
    selector: 'radar-slide',
    templateUrl: './radar-slide.html',
    imports: [IonicModule],
})
export class RadarSlideComponent extends PageBaseService {
  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;
  private slideOpts = {
    initialSlide: 1,
    speed: 500,
    pager: true,
    nested: true,
  };
  exibeBaixarApp = true;
  exibeAssinatura = !this.platform.is('ios');
  constructor(
    public modalController: ModalController,
    protected loadingCtrl: LoadingController,
    protected alertCtrl: AlertController,
    protected platform: Platform,
    private assinaturaService: AssinaturaService,
    private usuarioService: UsuarioService
  ) {
    super(loadingCtrl, alertCtrl, null, null);
  }
  ngOnInit() {
    localStorage.removeItem('TUTORIAL');

    this.ocultarLoading();
    if (!Capacitor.isNativePlatform()) {
      this.exibeBaixarApp = true;
    }
    this.assinaturaService
      .inicializar(this.usuarioService.get().Id)
      .subscribe((data) => { });
  }
  onSlidePrev()
  {
      this.swiperRef.nativeElement.swiper.slidePrev(1500);
  }
  onSlideNext()
  {
    this.swiperRef.nativeElement.swiper.slideNext(1000)
  }
  async planoClick() {
    if (!Capacitor.isNativePlatform()) {
      const modal = await this.modalController.create({
        component: MeuPlanoPage,
        cssClass: 'modal-pontuacao',
      });
      return await modal.present();
    }
    else
      this.assinaturaService.assinar();
  }

  async abrirCadastroRadar() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: RadarEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {},
    });
    this.ocultarLoading();
    modal.onDidDismiss().then(async () => {
      modal.remove();
      const modal2 = await this.modalController.create({
        component: RadarMonitoramentoPage,
        cssClass: 'modal-pontuacao',
      });
      modal2.present();
    });
    return await modal.present();
  }
  async newsletterClick() {
    const modal = await this.modalController.create({
      component: NewsletterListaPage,
      cssClass: 'modal-pontuacao',
      componentProps: {},
    });
    return await modal.present();
  }
  fechar() {
    this.modalController.dismiss({
    });
  }
  async configuracaoNotificacaoClick() {
    const modal = await this.modalController.create({
      component: ConfiguracaoNotificacaoPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  ocultarLoading() {
    var qtd = 0;
    setTimeout(() => {
      var interval = setInterval(() => {
        if (this.loading != null) {
          this.loading.dismiss();
          clearInterval(interval);
        } else {
          qtd++;
        }
        if (qtd == 6) {
          clearInterval(interval);
        }
      }, 1000);
    }, 1000);
  }
}
