import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiarioOficialUsuarioComponent } from './diario-oficial-usuario.component';
import { SegmentoEstadosModule } from '../segmento-estados/segmento-estados.module';


@NgModule({
    imports: [
        CommonModule,
        SegmentoEstadosModule,
        DiarioOficialUsuarioComponent
    ],
    exports: [
        DiarioOficialUsuarioComponent
    ]
})
export class DiarioOficialUsuarioModule { }
