import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconeInteiroTeorComponent } from './icone-inteiro-teor.component';



@NgModule({
  declarations: [IconeInteiroTeorComponent],
  imports: [
    CommonModule
  ],
  exports:[
    IconeInteiroTeorComponent
  ]
})
export class IconeInteiroTeorModule { }
