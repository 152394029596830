import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AgendaSessaoService } from '../api/agenda-sessao.service';
import { AssinaturaService } from '../api/assinatura.service';
import { UsuarioService } from '../api/usuario.service';
import { AgendaPage } from '../agenda/agenda.page';
import {
  ActionSheetController,
  AlertController,
  IonModal,
  LoadingController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { PageBaseService } from '../util/page-base.service';
import { Router } from '@angular/router';
import { ProjetoService } from '../api/projeto.service';
import { TypeaheadPage } from '../typeahead/typeahead.page';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { NgFor, NgIf, DatePipe } from '@angular/common';
import { IconeEmentaComponent } from '../icone-ementa/icone-ementa.component';
enum TipoFiltro {
  Radar,
  Comissao,
}
@Component({
    selector: 'app-agenda-component',
    templateUrl: './agenda-component.page.html',
    styleUrls: ['./agenda-component.page.scss'],
    imports: [
        NgFor,
        NgIf,
        IconeEmentaComponent,
        DatePipe,
    ],
})
export class AgendaComponentPage extends PageBaseService implements OnInit {
  @Input()
  idComissao: any;
  public dataSelecionadaStr = new Date();
  exibirSpinning = false;
  public date;
  dataSelecionada: Date;
  diaSemanaLista: string[];
  diaSemanaListaUtil: string[];
  diaMesLista: any[];
  diaMesListaUtil: any[];
  indexDiaAtual: any;
  visualizacao = 'semana';
  mesAtual: any;
  exibeSelecaoExport = false;
  agendaSelecao = [];
  filtro: any = undefined;
  tipoFiltro: TipoFiltro;
  itensSelecionados: any = [];
  @ViewChild('datePicker', { static: true }) datePicker;
  exibeCalendario = false;
  agendaSessaoLista: any;
  agendaSelecionada: any;
  plano: any = null;
  idUsuario: any = undefined;
  listaComissao: any;
  radarLista: any;
  @ViewChild('picker', { static: true }) modalPicker!: any;
  modal: HTMLIonModalElement;
  agendaSessaoListaBkp: any;
  radarListaBkp: any;
  constructor(
    private agendaSessaoService: AgendaSessaoService,
    protected router: Router,
    protected platform: Platform,
    private modalController: ModalController,
    private assinaturaService: AssinaturaService,
    private radarMonitoramentoService: RadarMonitoramentoService,
    private projetoService: ProjetoService,
    private usuarioService: UsuarioService,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    public actionSheetController: ActionSheetController
  ) {
    super(loadingController, alertCtrl, platform, router);
  }
  async abrirModalComissao() {
    if (this.visualizacao === 'dia')
      this.listaComissao = this.agendaSessaoLista.Sessoes.filter(
        (x) => x.Comissao != null
      ).map((x) => {
        return {
          Titulo:
            '(' +
            (x.Camara ? 'Câmara' : 'Senado') +
            ')' +
            x.Comissao.Sigla +
            ' - ' +
            x.Comissao.Nome,
          Id: x.Comissao.Id,
        };
      });
    else
      this.listaComissao = this.agendaSessaoLista.Sessoes.flatMap((x) =>
        x.Horarios.map((z) => z)
      )
        .flatMap((x) => x)
        .filter((x) => x.Comissao != null)
        .map((x) => {
          return {
            Titulo:
              x.Comissao.Sigla +
              ' - ' +
              x.Comissao.Nome +
              '(' +
              (x.Camara ? 'Câmara' : 'Senado') +
              ') ',
            Id: x.Comissao.Id,
          };
        });
    this.listaComissao = [
      ...new Map(this.listaComissao.map((item) => [item['Id'], item])).values(),
    ];
    this.modalController.dismiss();
    var that = this;
    this.modal = await this.modalController.create({
      component: TypeaheadPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        items: this.listaComissao,
        selectedItems: this.itensSelecionados,
        selectionChange: (itens) => {
          that.selecaoChangedComissao(itens, that);
        },
        selectionCancel: () => {
          this.modal.dismiss();
        },
        title: 'Filtrar por comissão',
      },
    });
    this.ocultarLoading();

    return await this.modal.present();
  }
  ngOnInit() {
    this.usuarioService
      .obterPlano(this.usuarioService.get().Id)
      .then((plano) => {
        this.plano = plano;
      });
    this.carregarCalendarioUi();
    this.carregarAgenda();
  }
  selecionarTodos($event: any) {
    if ($event.target.checked)
      this.agendaSelecao = this.agendaSessaoLista.Sessoes.flatMap(
        (x) => x.Horarios
      )
        .flatMap((x) => x)
        .map((x) => x.Id);
    else this.agendaSelecao = [];
  }
  isSelecionado(id) {
    return this.agendaSelecao.filter((x) => x == id).length > 0;
  }
  selecionarAgenda(agenda, ev) {
    if (ev.target.checked) {
      this.agendaSelecao.push(agenda.Id);
    } else
      this.agendaSelecao = this.agendaSelecao.filter((x) => x != agenda.Id);
  }
  formatarHorario(input: string) {
    const parts = input.split(':');
    return parts[0] + ':' + parts[1];
  }
  openDatePicker() {
    this.exibeCalendario = true;
  }
  removerFiltro(item) {
    this.filtro = this.filtro.filter((x) => x.Id != item.Id);
    this.idUsuario = undefined;
    this.selecaoChangedComissao(
      this.filtro.map((x) => x.Id),
      this
    );
  }

  abrirModalRadar() {
    this.radarMonitoramentoService
      .listar(this.usuarioService.get().Id)
      .then(async (data: any[]) => {
        this.radarLista = data.map((x) => {
          return {
            Titulo: x.Titulo,
            Id: x.Id,
          };
        });
        this.radarListaBkp = data;

        this.modalController.dismiss();
        var that = this;
        this.modal = await this.modalController.create({
          component: TypeaheadPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            items: this.radarLista,
            selectedItems: this.itensSelecionados,
            selectionChange: (itens) => {
              that.selecaoChangedRadar(itens, that);
            },
            selectionCancel: () => {
              this.modal.dismiss();
            },
            title: 'Filtrar por Radar',
          },
        });
        this.ocultarLoading();

        return await this.modal.present();
      });
  }
  async filterClick() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Filtrar agendas por',
      cssClass: '',
      buttons: [
        {
          text: 'Seus radares de monitoramento',
          icon: 'radio-outline',
          handler: () => {
            this.tipoFiltro = TipoFiltro.Radar;
            this.abrirModalRadar();
          },
        },
        {
          text: 'Comissão',
          icon: 'people-outline',
          handler: async () => {
            this.tipoFiltro = TipoFiltro.Comissao;
            await this.abrirModalComissao();
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            this.filtro = '';
            this.tipoFiltro = undefined;
          },
        },
      ],
    });
    await actionSheet.present();
  }

  selecaoChangedComissao(itens: any, that) {
    this.itensSelecionados = itens;
    that.modal.dismiss();
    if (this.visualizacao === 'dia')
      that.listaComissao = that.agendaSessaoListaBkp.Sessoes.filter(
        (x) => x.Comissao != null
      ).map((x) => {
        return {
          Titulo: x.Comissao.Sigla + ' - ' + x.Comissao.Nome,
          Id: x.Comissao.Id,
        };
      });
    else
      that.listaComissao = this.agendaSessaoLista.Sessoes.flatMap((x) =>
        x.Horarios.map((z) => z)
      )
        .flatMap((x) => x)
        .filter((x) => x.Comissao != null)
        .map((x) => {
          return {
            Titulo: x.Comissao.Sigla + ' - ' + x.Comissao.Nome,
            Id: x.Comissao.Id,
          };
        });
    that.filtro = that.listaComissao.filter((x) => itens.indexOf(x.Id) >= 0);
    that.filtro = [
      ...new Map(that.filtro.map((item) => [item['Id'], item])).values(),
    ];
    var sessoesLista: any = [...that.agendaSessaoListaBkp.Sessoes];
    if (that.visualizacao === 'dia')
      sessoesLista = sessoesLista.filter(
        (x) =>
          (x.Comissao && itens.indexOf(x.Comissao.Id) >= 0) || itens.length == 0
      );
  }

  exibeAgenda(agenda) {
    return (
      (agenda.Comissao &&
        this.itensSelecionados.indexOf(agenda.Comissao.Id) >= 0) ||
      this.itensSelecionados.length == 0
    );
  }
  selecaoChangedRadar(itens: any, that) {
    this.itensSelecionados = itens;
    that.modal.dismiss();
    that.radarLista = [...that.radarListaBkp];
    that.filtro = that.radarLista
      .map((x) => {
        return {
          Titulo: x.Titulo,
          Id: x.Id,
        };
      })
      .filter((x) => itens.indexOf(x.Id) >= 0);
    var radar = that.radarLista.filter((x) => itens.indexOf(x.Id) >= 0)[0];
    if (radar.Tags.length == 0) return;
    this.exibirSpinning = true;
    that.radarMonitoramentoService.preVisualizar(radar).then((data) => {
      this.exibirSpinning = false;
      var projetoLista = data.map((x) => x.Id);
      var sessoesLista = [...that.agendaSessaoListaBkp.Sessoes];

      sessoesLista = sessoesLista.filter((x) => {
        return projetoLista.some((projetoItem) =>
          x.ProjetoLista.map((z) => z.ProjetoLei.Id).includes(projetoItem)
        );
      });
      sessoesLista.forEach((sessao) => {
        sessao.ProjetoLista = sessao.ProjetoLista.filter((x) =>
          projetoLista.includes(x.ProjetoLei.Id)
        );
      });
      that.agendaSessaoLista.Sessoes = sessoesLista;
    });
  }
  async proposicaoClick(proposicao) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: proposicao.Id, IdUsuario: this.usuarioService.get().Id })
      .then(async (data) => {
        this.modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();

        return await this.modal.present();
      });
  }
  async agendaClick(agenda) {
    if (this.plano) {
      const modal = await this.modalController.create({
        component: AgendaPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          agenda,
        },
      });
      return await modal.present();
    } else {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {
          this.plano = data;
        });
    }
  }

  corData(diaMes) {
    var cor = 'light';
    if (diaMes == new Date().getDate()) {
      cor = 'dark';
    }
    return cor;
  }
  exportarClick() {
    this.exibirSpinning = true;
    this.agendaSessaoService
      .exportar({ idList: this.agendaSelecao, plano: this.plano.Nome })
      .then((data: string) => {
        this.exibirSpinning = false;
        window.open(data, '_system');
      });
  }
  async abrirMenu() {
    const actionSheet = await this.actionSheetController.create({
      cssClass: '',
      buttons: [
        {
          text: 'Filtrar por data',
          icon: 'calendar-outline',
          handler: () => {
            this.openDatePicker();
          },
        },
        {
          text: 'Filtrar por comissão',
          icon: 'people-outline',
          handler: async () => {
            await this.abrirModalComissao();
          },
        },
        {
          text: 'Semana',
          icon: 'grid-outline',
          handler: () => {
            this.agendaSessaoLista = undefined;
            this.visualizacao = 'semana';
            this.carregarCalendarioUi();
            this.carregarAgenda();
          },
        },
        {
          text: 'Dia',
          icon: 'square-outline',
          handler: () => {
            this.agendaSessaoLista = undefined;
            this.visualizacao = 'dia';
            this.carregarCalendarioUi();
            this.carregarAgenda();
          },
        },
        {
          text: 'Exportar',
          icon: 'document-outline',
          handler: () => {
            this.exibeSelecaoExport = true;
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  carregarCalendarioUi() {
    this.dataSelecionada = new Date();
    if (this.dataSelecionadaStr)
      this.dataSelecionada = new Date(this.dataSelecionadaStr);
    this.diaSemanaLista = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    this.diaSemanaListaUtil = ['S', 'T', 'Q', 'Q', 'S'];
    this.diaMesLista = [];
    this.diaMesListaUtil = [];
    var d = new Date(this.dataSelecionada);
    d.setDate(d.getDate() - d.getDay());

    for (let index = 0; index < 7; index++) {
      this.diaMesLista.push(d.getDate().toString());
      d.setDate(d.getDate() + 1);
    }

    d = new Date(this.dataSelecionada);
    d.setDate(d.getDate() + 1 - d.getDay());
    for (let index = 0; index < 5; index++) {
      this.diaMesListaUtil.push(d.getDate().toString());
      d.setDate(d.getDate() + 1);
    }
    this.indexDiaAtual = this.dataSelecionada.getDay();
    this.mesAtual = this.dataSelecionada.toLocaleDateString('default', {
      month: 'long',
    });
    // this.exibeCalendario = false;
  }
  fecharCalendario() {
    this.exibeCalendario = false;
  }
  dataCalendarioChange(event) {
    this.dataSelecionadaStr = event.detail.value;
    this.carregarCalendarioUi();
    this.carregarAgenda();
    this.exibeCalendario = false;
  }
  carregarAgenda() {
    // this.exibirLoading('');
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let date = new Date(this.dataSelecionada.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
    // this.agendaSessaoService.obterTexto(date.split('T')[0]).then((data) => {
    //   this.textoAgenda = data;
    //   // this.ocultarLoading();
    // });
    tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    date = new Date(this.dataSelecionada.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
    var dateAte = new Date();
    dateAte.setDate(this.dataSelecionada.getDate() + 6);
    var dateAteStr = dateAte.toISOString().slice(0, -1);
    var funcao = this.idComissao
      ? this.agendaSessaoService.listarPorComissao(
          this.idComissao,
          date.split('T')[0]
        )
      : this.visualizacao === 'dia'
      ? this.agendaSessaoService.listar({
          data: date.split('T')[0],
          idUsuario: this.idUsuario,
        })
      : this.agendaSessaoService.listarPorPeriodo({
          dataDe: date.split('T')[0],
          dataAte: dateAteStr.split('T')[0],
          idUsuario: this.idUsuario,
        });
    this.exibirSpinning = true;
    funcao.then((data) => {
      this.exibirSpinning = false;

      this.agendaSessaoLista = data;
      this.agendaSessaoListaBkp = Object.assign({}, data);
      if (this.tipoFiltro !== undefined) {
        if (this.tipoFiltro == TipoFiltro.Comissao)
          this.selecaoChangedComissao(this.itensSelecionados, this);
        else if (this.tipoFiltro == TipoFiltro.Radar)
          this.selecaoChangedRadar(this.itensSelecionados, this);
      }
      // this.ocultarLoading();
    });
  }

  diaChanged(ev: any) {
    this.dataSelecionada.setDate(
      this.dataSelecionada.getDate() -
        (this.indexDiaAtual - parseInt(ev.detail.value))
    );
    this.indexDiaAtual = ev.detail.value;
    this.carregarAgenda();
  }
}
