/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import {
  Platform,
  LoadingController,
  NavController,
  AlertController,
  ModalController,
  IonSearchbar,
  ToastController,
} from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
// import { NetworkProvider } from './util/networkProvider';
import { PainelMenuPage as PainelMenuPage } from './painel-menu/painel-menu.page';
import { PushService } from './api/push.service';
import { UsuarioService } from './api/usuario.service';
import { NetworkService } from './api/network.service';
import { of, Subject } from 'rxjs';
import { MenuService } from './util/menu.service';
import { ProjetoService } from './api/projeto.service';
import { ParlamentarService } from './api/parlamentar.service';
import { BuscaService } from './api/busca.service';
import { ProposicaoPage } from './proposicao/proposicao.page';
import { ParlamentarComponent } from './parlamentar/parlamentar.component';
import { VotoParlamentarPage } from './voto-parlamentar/voto-parlamentar.page';
import { SessaoPlenarioService } from './api/sessao-plenario.service';
import { DocumentoProjetoService } from './api/documento-projeto.service';
import { ComissaoService } from './api/comissao.service';
import { ComissaoPage } from './comissao/comissao.page';
import { PrazoPage } from './prazo/prazo.page';
import { RadarMonitoramentoService } from './api/radar-monitoramento.service';
import { RadarEdicaoPage } from './radar-edicao/radar-edicao.page';
import { RadarMonitoramentoPage } from './radar-monitoramento/radar-monitoramento.page';
import { HomeService } from './api/home.service';
import { NotificacaoUsuarioPage } from './notificacao-usuario/notificacao-usuario.page';
import { NotificacaoUsuarioService } from './api/notificacao-usuario.service';
import { HomePage } from './home/home.page';
import { ParlamentaresPage } from './parlamentares/parlamentares.page';
import { AgendaPage } from './agenda/agenda.page';
import { AgendaSessaoService } from './api/agenda-sessao.service';
import { RetrospectivaService } from './api/retrospectiva.service';
import { AssinaturaService } from './api/assinatura.service';
import { MeuPlanoPage } from './meu-plano/meu-plano.page';
import { RetrospectivaComponent } from './retrospectiva/retrospectiva.component';
import { Capacitor } from '@capacitor/core';
import { Cordova } from '@awesome-cordova-plugins/core';
import { DiscursoService } from './api/discurso.service';
import { TokenService } from './api/token.service';
import { register } from 'swiper/element/bundle';
import { UpdateService } from './api/update.service';
import { TwitterService } from './api/twitter.service';
import { NoticiaService } from './api/noticia.service';
register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: false,
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isItemAvailable = false;
  conecta = false;
  autoCompleteLista: any[];
  @ViewChild('searchbar') searchbar: any;
  nomeUsuario: string;
  urlImagemPerfil: string;
  loading: HTMLIonLoadingElement;
  prompt: any;
  exibeBack: boolean;
  tituloMenu = '';
  filtro = { Next: false, Pagina: 1, Tipo: '', Termo: '' };
  clickPesquisaChip = false;
  private subject = new Subject<any>();
  exibeBuscaFlag = false;
  buscaLista = undefined;
  notificacaoLista: any[];
  autoCompleteListaBkp: any[];
  plano: any;
  qtdRadar = 0;
  exibeSearchBar = true;
  @ViewChild('search') search;
  qtdNotificacaoHoje: number;
  constructor(
    public platform: Platform,
    private loadingController: LoadingController,
    private ngZone: NgZone,
    public router: Router,
    private navCtrl: NavController,
    // private networkProvider: NetworkProvider,
    private alertController: AlertController,
    private modalController: ModalController,
    private pushService: PushService,
    private usuarioService: UsuarioService,
    private menuService: MenuService,
    private documentoService: DocumentoProjetoService,
    private comissaoService: ComissaoService,
    private noticiaService: NoticiaService,
    private projetoService: ProjetoService,
    private homeService: HomeService,
    private updateService: UpdateService,
    private parlamentarService: ParlamentarService,
    private twitterService: TwitterService,
    private agendaSessaoService: AgendaSessaoService,
    private zone: NgZone,
    private buscaService: BuscaService,
    private sessaoPlenarioService: SessaoPlenarioService,
    private radarMonitoramentoService: RadarMonitoramentoService,
    private notificacaoUsuarioService: NotificacaoUsuarioService,
    private retrospectivaService: RetrospectivaService,
    private toastController: ToastController,
    private navController: NavController,
    private assinaturaService: AssinaturaService,
    private discursoService: DiscursoService,
    private tokenService: TokenService,
    private route: ActivatedRoute
  ) {
    try {
      this.initializeApp();
    } catch (error) {
      alert(error);
    }
  }

  assinar() {
    this.assinaturaService
      .inicializar(this.usuarioService.get().Id)
      .subscribe((data) => {
        this.plano = data;
      });
    this.assinaturaService.assinar();
  }
  carregarAutoComplete() {
    this.buscaService.listarTermos().then(async (data: any[]) => {
      this.autoCompleteLista = data;
      this.autoCompleteListaBkp = data;
    });
  }

  autoCompleteClick(buscaItem) {
    this.exibirLoading('Carregando...');
    switch (buscaItem.Tipo) {
      case 'proposicao':
        {
          this.projetoService
            .obter({
              Id: buscaItem.Id,
              IdUsuario: this.usuarioService.get().Id,
            })
            .then(async (data) => {
              const modal = await this.modalController.create({
                component: ProposicaoPage,
                cssClass: 'modal-pontuacao',
                componentProps: {
                  proposicao: data,
                },
              });
              this.ocultarLoading();
              return await modal.present();
            });
        }
        break;
      case 'tweet':
        {
          this.twitterService.obter(buscaItem.Id).then((data: any) => {
            window.open(
              'https://twitter.com/i/web/status/' + data.IdTweet,
              '_system'
            );
            this.ocultarLoading();
          });
        }
        break;
      case 'noticia':
        {
          this.noticiaService.obter(buscaItem.Id).then((data: any) => {
            window.open(data.Url, '_system');
            this.ocultarLoading();
          });
        }
        break;
      case 'parlamentar':
        {
          this.parlamentarService
            .obter({ Id: buscaItem.Id })
            .then(async (data) => {
              const modal = await this.modalController.create({
                component: ParlamentarComponent,
                cssClass: 'modal-pontuacao',
                componentProps: {
                  parlamentar: data,
                },
              });
              this.ocultarLoading();
              return await modal.present();
            });
        }
        break;
      case 'comissao':
        {
          this.comissaoService
            .obter({ Id: buscaItem.Id })
            .then(async (data) => {
              const modal = await this.modalController.create({
                component: ComissaoPage,
                cssClass: 'modal-pontuacao',
                componentProps: {
                  comissao: data,
                },
              });
              this.ocultarLoading();
              return await modal.present();
            });
        }
        break;
    }
  }

  getItemAutocomplete(ev: any) {
    if (ev.target.value.length >= 3) {
      const val = ev.target.value;
      if (val && val.trim() !== '') {
        this.isItemAvailable = true;
        this.autoCompleteLista = this.autoCompleteListaBkp
          .filter((x) => x.Tipo == this.filtro.Tipo || this.filtro.Tipo == '')
          .filter((item) => {
            return (
              item.Termo.toLowerCase().substring(0, ev.target.value.length) ==
                val.toLowerCase() ||
              item.Subtitulo.toLowerCase().indexOf(val.toLowerCase()) > -1
            );
          })
          .map((item) => {
            var itemReplace = JSON.parse(JSON.stringify(item));
            var regEx = new RegExp(val, 'ig');
            itemReplace.Subtitulo = itemReplace.Subtitulo.replace(
              regEx,
              '<b>' + val.toUpperCase() + '</b>'
            );
            return itemReplace;
          })
          .slice(0, 100);
      } else {
        this.isItemAvailable = false;
      }
    } else {
      this.autoCompleteLista = [];
      this.isItemAvailable = false;
    }
  }

  async exibirMenu() {
    const modal = await this.modalController.create({
      component: PainelMenuPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  async criarLoading(texto: string) {
    return await this.loadingController.create({
      message: texto,
    });
  }

  renderizarLoading(texto) {
    return new Promise<HTMLIonLoadingElement>(async (resolve) => {
      this.criarLoading(texto).then(async (x) => {
        this.loading = x;
        await this.loading.present();
        resolve(x);
      });
    });
  }
  navegarHome() {
    window.location.reload();
  }
  async exibirLoading(texto: string) {
    try {
      this.loading.dismiss().then(() => {
        this.renderizarLoading(texto);
      });
    } catch (error) {
      this.renderizarLoading(texto);
    }
  }
  async exibeRadar() {
    if (!this.usuarioService.get()) {
      window.location.href = '/#/primeiro-acesso';
      window.location.reload();
    }
    const modal = await this.modalController.create({
      component: RadarMonitoramentoPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }
  async exibeNotificacao() {
    this.notificacaoLista = undefined;
    const modal = await this.modalController.create({
      component: NotificacaoUsuarioPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }
  abrirDocumento(idDocumento) {
    this.exibirLoading('Carregando...');
    this.documentoService
      .obter(idDocumento)
      .then(async (dataDocumento: any) => {
        this.projetoService
          .obter({ Id: dataDocumento.IdProjeto })
          .then(async (data) => {
            const modal = await this.modalController.create({
              component: ProposicaoPage,
              cssClass: 'modal-pontuacao',
              componentProps: {
                proposicao: data,
                aba: 'documentos',
              },
            });
            this.ocultarLoading();
            return await modal.present();
          });
      });
  }
  async abrirTwitter(proposicao) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: proposicao.Id, IdUsuario: this.usuarioService.get().Id })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
            aba: 'twitter',
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }

  async proposicaoClick(proposicao) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: proposicao.Id, IdUsuario: this.usuarioService.get().Id })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }
  carregarCache() {
    this.projetoService.listar({});
    this.parlamentarService.listar({
      Senadores: true,
      Deputados: true,
      Nome: '',
    });
    this.homeService.listar(this.usuarioService.get().Id, null);
    this.sessaoPlenarioService.listar({
      TipoVotacao: 'nominal',
      TipoVotacaoModel: true,
      Senado: true,
      Camara: true,
      Titulo: '',
      Next: false,
      Pagina: 0,
      QtdRegistroPagina: 100,
      Voto: '',
      NomeParlamentar: '',
      Partido: '',
      PalavraChave: '',
    });
  }
  async parlamentarClick(rankingItem) {
    this.exibirLoading('Carregando...');
    this.parlamentarService.obter({ Id: rankingItem.Id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ParlamentarComponent,
        cssClass: 'modal-pontuacao',
        componentProps: {
          parlamentar: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  async agendaClick(id) {
    this.agendaSessaoService.obter(id).then(async (agenda) => {
      this.zone.run(async () => {
        this.exibirLoading('Carregando...');
        const modal = await this.modalController.create({
          component: AgendaPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            agenda,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
    });
  }
  comissaoClick(id) {
    this.exibirLoading('Carregando...');
    this.comissaoService.obter({ Id: id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ComissaoPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          comissao: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }
  async sessaoClick(projetoSessaoPlenario) {
    this.exibirLoading('Carregando...');
    const modal = await this.modalController.create({
      component: VotoParlamentarPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        projetoSessaoPlenario,
      },
    });
    this.ocultarLoading();
    return await modal.present();
  }

  tratarDeepLink(pathObj) {
    var path = pathObj.path.toLowerCase();
    var route = '/';
    switch (path) {
      case '/app/projetolei':
        route = '/tabs/proposicoes';
        break;
      case '/app/comissao/listar':
        route = '/tabs/comissoes';
        break;
      case '/app/parlamentar':
        route = '/tabs/parlamentares';
        break;
      case '/app/autoridade/ministro':
        route = '/tabs/autoridades';
        break;
      case '/app/sessaoplenario/listar':
        route = '/tabs/votacoes';
        break;
      case '/app/prazo':
        route = '/tabs/prazo';
      case '/app/projetolei/editar':
        route = '/tabs/prazo';
        break;
      case '/app/documentoprojeto/push':
        var idDocumento = pathObj.queryString.split('=')[1];
        this.abrirDocumento(idDocumento);
        break;
      case '/app/projetolei/push':
        var id = pathObj.queryString.split('=')[1];
        this.proposicaoClick({ Id: id });
        break;
      case '/app/parlamentar/visualizar':
        var id = pathObj.queryString.split('=')[1];
        this.parlamentarClick(id);
        break;
      case '/app/agendasessao/push':
        var id = pathObj.queryString.split('=')[1];
        this.agendaClick(id);
        break;
      case '/app/comissao/push':
        var id = pathObj.queryString.split('=')[1];
        this.comissaoClick(id);
        break;
      case '/app/sessaoplenario/push':
        var id = pathObj.queryString.split('=')[1];
        this.sessaoPlenarioService.obter(id).then((data) => {
          this.sessaoClick(data);
        });
        break;
      case '/app/radar/share':
        var id = pathObj.queryString.split('=')[1];
        this.radarMonitoramentoService
          .ingressar({
            IdLinkRadar: id,
            IdUsuario: this.usuarioService.get().Id,
          })
          .then(async (data: any) => {
            const alert = await this.alertController.create({
              cssClass: 'modal-pontuacao',
              header: 'Compartilhamento de radar',
              message: data,
              buttons: ['OK'],
            });
            await alert.present();
          });
        break;
      default:
        break;
    }
    if (route == '/') {
      if (path.indexOf('/app/projetolei/push/') > -1) {
        var id = path.split('/').slice(-1)[0];
        this.proposicaoClick({ Id: id });
      } else if (path.indexOf('/app/projetolei/editar') > -1) {
        if (pathObj.fragment == 'twitter') {
          var id = pathObj.path.split('/')[pathObj.path.split('/').length - 1];
          this.abrirTwitter({ Id: id });
        }
      }
    }
    if (route) this.router.navigateByUrl(route);
  }
  configurarDeepLink() {
    // this.deeplinks
    //   .routeWithNavController(this.navController, {
    //     '/home': HomePage,
    //     '/parlamentar': ParlamentaresPage,
    //   })
    //   .subscribe(
    //     (match) => {
    //       // match.$route - the route we matched, which is the matched entry from the arguments to route()
    //       // match.$args - the args passed in the link
    //       // match.$link - the full link data
    //       console.log('Successfully matched route', match.$link.path);
    //       try {
    //         this.tratarDeepLink(match.$link);
    //       } catch (error) { }
    //     },
    //     (nomatch) => {
    //       // nomatch.$link - the full link data
    //       console.error("Got a deeplink that didn't match", nomatch);
    //     }
    //   );
  }
  ingressarRadar(id) {
    this.exibirLoading('Ingressando no radar...');
    this.radarMonitoramentoService
      .ingressar({
        IdLinkRadar: id,
        IdUsuario: this.usuarioService.get().Id,
      })
      .then(async (data: any) => {
        const alert = await this.alertController.create({
          cssClass: 'modal-pontuacao',
          header: 'Você ingressou em um Radar de monitoramento legislativo',
          message: data,
          buttons: ['OK'],
        });
        alert.onDidDismiss().then(() => {
          window.location.href = '/';
        });
        await alert.present();
        this.loading.dismiss();
      });
  }

  rotaLiberada() {
    var url = window.location.href.toLowerCase();
    if (!Capacitor.isNativePlatform()) return true;
    else
      return (
        url.indexOf('home') > -1 ||
        url.indexOf('busca') > -1 ||
        url.indexOf('primeiro-acesso') > -1 ||
        url.indexOf('proposicao') > -1 ||
        url.indexOf('parlamentar') > -1 ||
        url.indexOf('comissao') > -1 ||
        url.indexOf('busca') > -1
      );
  }
  async exibeAssinatura() {
    const modal = await this.modalController.create({
      component: MeuPlanoPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }
  checkarUpdate() {
    this.updateService.performImmediateUpdate().then((data) => {});
  }
  ngOnInit() {
    this.checkarUpdate();
    this.tokenService.loadToken();
    this.configurarDeepLink();
    var passouIngresso = false;
    var url = window.location.href.toLowerCase();
    if (url.indexOf('busca') > -1) {
      var param = decodeURIComponent(
        window.location.hash.split('/')[3]
      ).replace('+', ' ');

      if (url.indexOf('termo') > -1) {
        param = decodeURI(window.location.hash.split('termo=')[1]);
      }
      var that = this;
      setTimeout(() => {
        this.ngZone.run(() => {
          that.exibeBusca();
          that.filtro.Termo = param;
          that.filtroBuscaClick(undefined);
        });
      }, 3000);
    }
    this.router.events.subscribe((val) => {
      if (url.indexOf('assinatura') > -1 && !passouIngresso) {
        if (!this.usuarioService.get()) {
          localStorage.setItem('REDIRECT_URL', window.location.href);
          window.location.href = '/#/primeiro-acesso';
          window.location.reload();
        }
        this.exibeAssinatura();
        passouIngresso = true;
      } else if (url.indexOf('radar-share') > -1) {
        const usuario = this.usuarioService.get();
        if (!usuario && !passouIngresso) {
          passouIngresso = true;
          localStorage.setItem('REDIRECT_URL', window.location.href);
          window.location.href = '/#/primeiro-acesso';
          window.location.reload();
        } else if (!passouIngresso) {
          passouIngresso = true;
          var param = decodeURI(window.location.hash.split('/')[2]);
          this.ingressarRadar(param);
        }
      } else {
        // window.location.href = url;
        // window.location.reload();
      }
      this.fecharBusca();
    });
    //this.carregarCache();
    this.carregarAutoComplete();
    this.ngZone.run(() => {
      this.menuService.subject.subscribe((tituloMenu) => {
        this.tituloMenu = tituloMenu;
      });
      this.menuService.subjectBack.subscribe((exibeBack) => {
        this.exibeBack = exibeBack;
      });
    });

    const usuario = this.usuarioService.get();
    // if (
    //   (window.location.href.indexOf('retrospectiva') > -1 ||
    //     !localStorage.getItem('RETRO')) &&
    //   !this.platform.is('ios')
    // )
    if (false) {
      var id =
        window.location.href.split('=')[1] ?? this.usuarioService.get().Id;

      this.exibirLoading('Carregando sua retrospectiva...');
      //this.router.navigateByUrl('/primeiro-acesso');
      this.retrospectivaService.gerar(id).then(async (data) => {
        localStorage.setItem('RETRO', 'true');
        const modal = await this.modalController.create({
          component: RetrospectivaComponent,
          cssClass: 'modal-pontuacao',
          componentProps: {
            model: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
    } else if (window.location.href.indexOf('/#/radar') > -1) {
      this.exibeRadar();
    } else if (window.location.href.indexOf('/#/notificacao') > -1) {
      this.exibeNotificacao();
    }

    if (!usuario && !this.rotaLiberada()) {
      if (!Capacitor.isNativePlatform())
        localStorage.setItem('REDIRECT_URL', window.location.href);
      this.router.navigateByUrl('/primeiro-acesso');
      this.plano = true;
    } else if (usuario) {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {
          this.plano = data;
        });
      this.usuarioService.obterPlano(usuario.Id).then((plano) => {
        this.plano = plano;
        this.conecta = this.plano.Nome.toLowerCase().indexOf('conecta') >= 0;
        if (!this.plano && this.qtdRadar > 0) this.presentToastAssinatura();
      });
      this.verificarQtdRadarUsuario();
      this.carregarListaNotificacao();
    }
  }

  exibeBusca() {
    this.filtro.Termo = '';
    this.filtro.Tipo = '';
    this.exibeBuscaFlag = true;
    this.exibeSearchBar = true;
    var that = this;
    setTimeout(() => {
      that.search.setFocus();
    }, 1000);
  }

  fecharBusca() {
    this.exibeBuscaFlag = false;
    this.limparBusca();
  }
  onImgError(item) {
    if (item.Tipo == 'DOU') {
      item.Foto = '/assets/poderexecutivo.png';
    } else {
      item.Foto = '/assets/imagem-perfil.jpg';
      item.Imagem = '/assets/imagem-perfil.jpg';
    }
  }

  limparBusca() {
    this.filtro.Termo = '';
    this.buscaLista = undefined;
    this.autoCompleteLista = [];
    this.isItemAvailable = false;
  }

  async presentToastPrimeiroRadar() {
    const toast = await this.toastController.create({
      message: '',
      duration: 10000,
      position: 'bottom',
      color: 'danger',
      cssClass: 'toast-radar',
      buttons: [
        {
          side: 'start',
          icon: 'radio-outline',
          text: 'Cadastre seu primeiro radar',
          handler: () => {
            this.abrirCadastroRadar();
          },
        },
      ],
    });
    toast.onDidDismiss().then((e) => {});
    toast.present();
  }

  async presentToastAssinatura() {
    const toast = await this.toastController.create({
      message:
        '<b>Falha no pagamento</b><br/>Atualize a sua forma de pagamento o quanto antes para continuar aproveitando as funcionalidades <b>Premium</b> como monitoramento ilimitado, newsletter, redes sociais, notícias e DOU<br/><h3><ion-icon name="card-outline"></ion-icon>Adicionar forma de pagamento</h3>',
      position: 'top',
      color: 'danger',
      cssClass: 'toast-assinatura',
      // buttons: [
      //   {
      //     // side: 'end',
      //     side: 'end',
      //     role: 'close',
      //     icon: 'card-outline',
      //     handler: () => {
      //       this.exibeAssinatura();
      //     },
      //   },
      // ],
    });
    toast.onclick = () => {
      toast.dismiss();
      this.exibeAssinatura();
    };
    toast.onDidDismiss().then((e) => {
      // this.exibeAssinatura();
    });
    toast.present();
  }

  async abrirCadastroRadar() {
    this.modalController.dismiss();
    const modal = await this.modalController.create({
      component: RadarEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {},
    });
    this.ocultarLoading();
    modal.onDidDismiss().then(async () => {
      modal.remove();
      const modal2 = await this.modalController.create({
        component: RadarMonitoramentoPage,
        cssClass: 'modal-pontuacao',
      });
      modal2.present();
    });
    return await modal.present();
  }

  verificarQtdRadarUsuario() {
    this.radarMonitoramentoService
      .listar(this.usuarioService.get().Id)
      .then((radarLista: any[]) => {
        this.qtdRadar = radarLista.length;
        if (radarLista.length == 0) {
          this.presentToastPrimeiroRadar();
        }
      });
  }

  resultadoBuscaClick(resultado) {
    switch (resultado.Tipo) {
      case 'DOU':
        window.open(resultado.Url, '_system');
        break;
      case 'proposicao':
        this.exibirLoading('Carregando...');
        this.projetoService
          .obter({ Id: resultado.Id, IdUsuario: this.usuarioService.get().Id })
          .then(async (data) => {
            const modal = await this.modalController.create({
              component: ProposicaoPage,
              cssClass: 'modal-pontuacao',
              componentProps: {
                proposicao: data,
              },
            });
            this.ocultarLoading();
            return await modal.present();
          });
        break;
      case 'Discurso':
        this.exibirLoading('Carregando...');
        this.discursoService.obter(resultado.Id).then(async (data: any) => {
          this.projetoService
            .obter({
              Id: data.IdProjeto,
              IdUsuario: this.usuarioService.get().Id,
            })
            .then(async (data) => {
              const modal = await this.modalController.create({
                component: ProposicaoPage,
                cssClass: 'modal-pontuacao',
                componentProps: {
                  proposicao: data,
                  aba: 'discursos',
                },
              });
              this.ocultarLoading();
              return await modal.present();
            });
        });
        break;
      case 'votacao':
        this.exibirLoading('Carregando...');
        this.sessaoPlenarioService
          .listarPorId(resultado.Id)
          .then(async (data) => {
            const modal = await this.modalController.create({
              component: VotoParlamentarPage,
              cssClass: 'modal-pontuacao',
              componentProps: {
                projetoSessaoPlenario: data,
              },
            });
            this.ocultarLoading();
            return await modal.present();
          });
        break;
      case 'comissao':
        this.exibirLoading('Carregando...');
        this.comissaoService.obter({ Id: resultado.Id }).then(async (data) => {
          const modal = await this.modalController.create({
            component: ComissaoPage,
            cssClass: 'modal-pontuacao',
            componentProps: {
              comissao: data,
            },
          });
          this.ocultarLoading();
          return await modal.present();
        });
        break;
      case 'documento':
        this.exibirLoading('Carregando...');
        this.documentoService
          .obter(resultado.Id)
          .then(async (dataDocumento: any) => {
            this.projetoService
              .obter({ Id: dataDocumento.IdProjeto })
              .then(async (data) => {
                const modal = await this.modalController.create({
                  component: ProposicaoPage,
                  cssClass: 'modal-pontuacao',
                  componentProps: {
                    proposicao: data,
                    aba: 'documentos',
                  },
                });
                this.ocultarLoading();
                return await modal.present();
              });
          });
        break;
      case 'parlamentar':
        this.exibirLoading('Carregando...');
        this.parlamentarService
          .obter({ Id: resultado.Id })
          .then(async (data) => {
            const modal = await this.modalController.create({
              component: ParlamentarComponent,
              cssClass: 'modal-pontuacao',
              componentProps: {
                parlamentar: data,
              },
            });
            this.ocultarLoading();
            return await modal.present();
          });
        break;
      default:
        break;
    }
  }

  filtroBuscaClick(tipo) {
    if (this.filtro.Tipo == tipo) {
      this.filtro.Tipo = '';
    } else {
      this.filtro.Tipo = tipo;
    }
    this.getItemAutocomplete({ target: { value: this.filtro.Termo } });
  }

  prazoAberto(dateString) {
    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(dateString);

    var date1 = dateObject.getTime();
    var dataAtual = new Date().getTime();
    return date1 >= dataAtual; //se a data do parametro for maior que a data atual significa q o prazo ta aberto
  }

  carregarListaNotificacao() {
    this.notificacaoUsuarioService
      .listar(this.usuarioService.get().Id)
      .then((data) => {
        this.notificacaoLista = data as any[];
        this.qtdNotificacaoHoje = this.notificacaoLista.filter((x) => {
          var dataObj = new Date(x.Data);
          dataObj.setHours(dataObj.getHours() + 12);
          var dataAtual = new Date().getTime();

          if (dataObj.getTime() >= dataAtual) {
            return true;
          } else return false;
        }).length;
        if (this.notificacaoLista && this.notificacaoLista.length > 0) {
          var dataObj = new Date(this.notificacaoLista[0].Data);
          dataObj.setHours(dataObj.getHours() + 1);
          var dataAtual = new Date().getTime();

          if (
            dataObj.getTime() >= dataAtual &&
            localStorage.getItem('dataObj') != dataObj.getTime().toString()
          ) {
            localStorage.setItem('dataObj', dataObj.getTime().toString());
            this.exibeNotificacao();
          }
        }
        this.ocultarLoading();
      });
  }
  ocultarLoading() {
    var qtd = 0;
    setTimeout(() => {
      var interval = setInterval(() => {
        if (this.loading != null) {
          this.loading.dismiss();
          clearInterval(interval);
        } else {
          qtd++;
        }
        if (qtd == 5) {
          clearInterval(interval);
        }
      }, 1000);
    }, 1000);
  }

  backClick() {
    this.menuService.publishBack(false);
    this.subject.next('backEventHandler');
  }

  iniciarGoogleAnalytics() {
    // this.ga
    //   .startTrackerWithId('UA-186501508-1')
    //   .then(() => {
    //     console.log('Google analytics is ready now');
    //     this.ga.trackView('test');
    //     this.ga.trackView('Screen Title', '', true);
    //     //To track an Event:
    //     this.ga.trackEvent('Category', 'Action', 'Label', this.usuarioService.get().Email);
    //     // Tracker is ready
    //     // You can now track pages or set additional information such as AppVersion or UserId
    //   })
    //   .catch((e) => console.log('Error starting GoogleAnalytics', e));
  }

  initializeApp() {
    if (window.location.href.indexOf('categoria') > -1) {
      var categoriaObj = window.location.href.split('&')[0];
      var tituloObj = window.location.href.split('&')[1];
      var categoria = categoriaObj.split('=')[1];
      var titulo = tituloObj.split('=')[1].replace('-', ' ').replace('-', '/');
      var filtro = {
        Senado: categoria == 'senado',
        Camara: categoria == 'camara',
        Titulo: titulo,
        Next: false,
        Pagina: 0,
        QtdRegistroPagina: 10,
        Votado: null,
        Presidencia: null,
      };

      this.projetoService.listar(filtro).then((data: any[]) => {
        this.proposicaoClick(data[0]);
      });
    }
    this.platform.ready().then(() => {
      this.iniciarGoogleAnalytics();
      // this.networkProvider.initializeNetworkEvents();
      // this.nativeAudio.preloadComplex('uniqueId1', 'assets/win.mp3', 1, 1, 0).then((response) => { }, (response) => { });
    });
  }
}
