import { Component, Input, NgZone, OnInit } from '@angular/core';
import { UsuarioService } from '../api/usuario.service';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { ToastController, AlertController, LoadingController, ModalController, ActionSheetController, IonicModule } from '@ionic/angular';
import { PageBaseService } from '../util/page-base.service';
import { async } from 'rxjs';
import { AssinaturaService } from '../api/assinatura.service';
import { DiarioOficialService } from '../api/diario-oficial.service';
import { RadarDiarioMonitoramentoService } from '../api/radar-diario-monitoramento.service';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-radar-diario-edicao',
    templateUrl: './radar-diario-edicao.page.html',
    styleUrls: ['./radar-diario-edicao.page.scss'],
    imports: [
        IonicModule,
        NgIf,
        FormsModule,
        NgFor,
        DatePipe,
    ],
})
export class RadarDiarioEdicaoPage extends PageBaseService implements OnInit {
  @Input()
  radar: any;
  exibirSpinning = false;
  plano: any = {};
  diarioLista = [];
  qtdRegistrosPagina = 10;
  paginaAtualDiario = this.qtdRegistrosPagina;
  diarioListaBkp = [];
  carregandoDOU = false;
  filtroSecaoDou = 'Todas as seções';
  filtroOrgaoDou = 'Todos os orgãos';
  secaoFiltroLista = ['Todos os orgãos'];
  orgaoFiltroLista = ['Todas as seções'];
  filtroAssunto = false;
  diarioFiltroLista: any;
  diarioOrgaoFiltroLista: any;
  tagsPopDou: any[];
  diarioListaPaginacao: any[];
  constructor(
    private radarDiarioMonitoramentoService: RadarDiarioMonitoramentoService,
    private usuarioService: UsuarioService,
    private radarMonitoramentoService: RadarMonitoramentoService,
    private diarioOficialService: DiarioOficialService,
    private vibration: Vibration,
    private toastController: ToastController,
    protected alertCtrl: AlertController,
    protected actionSheetController: ActionSheetController,
    private zone: NgZone,
    private assinaturaService: AssinaturaService,
    private modalController: ModalController,
    protected loadingController: LoadingController
  ) {
    super(loadingController, alertCtrl, null, null);
  }

  ngOnInit() {
    this.carregarListaFiltro();
    var usuario = this.usuarioService.get();
    if (!usuario) {
      window.location.href = '/#/primeiro-acesso';
      window.location.reload();
    }

    if (!this.radar || !this.radar.Titulo) {
      this.radar = {
        FiltroLocalLista: [],
        DOEDF: false,
        DOESP: false,
        DOERS: false,
        DOERJ: false,
        DOEBA: false,
        DOEAL: false,
        DOETO: false,
        DOMUNSP: false,
        DOU: false,
        EUA: false,
        Autor: [],
        Tags: [],
        FiltroLista: [],
        AutorItem: '',
        AssuntoItem: '',
        IdUsuario: this.usuarioService.get().Id,
      };
      this.usuarioService
        .obterPlano(this.usuarioService.get().Id)
        .then((data) => {
          this.plano = data;
        });
    } else {
      this.radar.FiltroLocalLista.forEach((filtroLocal) => {
        if (filtroLocal.Nome == 'DOEDF') {
          this.radar.DOEDF = true;
        } else if (filtroLocal.Nome == 'DOERJ') {
          this.radar.DOERJ = true;
        } else if (filtroLocal.Nome == 'DOEAL') {
          this.radar.DOEAL = true;
        } else if (filtroLocal.Nome == 'DOEBA') {
          this.radar.DOEBA = true;
        } else if (filtroLocal.Nome == 'DOERS') {
          this.radar.DOERS = true;
        }
        else if (filtroLocal.Nome == 'DOEGO') {
          this.radar.DOEGO = true;
        }
        else if (filtroLocal.Nome == 'DOETO') {
          this.radar.DOETO = true;
        }
        else if (filtroLocal.Nome == 'DOERO') {
          this.radar.DOERO = true;
        }
        else if (filtroLocal.Nome == 'DOEMS') {
          this.radar.DOEMS = true;
        }
        else if (filtroLocal.Nome == 'DOEMT') {
          this.radar.DOEMT = true;
        }
      });
    }
    this.preVisualizar();
  }

  doInfinite(event) {
    this.doInfiniteDiario(event);
  }
  doInfiniteDiario(event) {
    this.zone.run(() => {
      this.paginaAtualDiario = this.paginaAtualDiario + this.qtdRegistrosPagina;
      this.diarioListaPaginacao = this.diarioLista.slice(
        0,
        this.paginaAtualDiario
      );
      if (event) {
        event.target.complete();
      }
    });
  }
  carregarDiarioOficial() {
    this.carregandoDOU = true;
    this.exibirSpinning = true;
    this.diarioListaBkp = [];
    if (this.radar) {
      this.tagsPopDou = [...this.radar.Tags];
      this.diarioFiltroLista = {};
      this.carregarDiarioRecursivo();
    }
  }
  carregarListaFiltro() {
    this.exibirSpinning = true;
    this.diarioOficialService.listarOrgao().then((data: any) => {
      this.orgaoFiltroLista = data;
      this.orgaoFiltroLista.unshift('Todos os orgãos');
      this.exibirSpinning ==
        !(this.secaoFiltroLista.length > 0 && this.orgaoFiltroLista.length > 0);
    });
    this.diarioOficialService.listarSecao().then((data: any) => {
      this.secaoFiltroLista = data;
      this.secaoFiltroLista.unshift('Todas as seções');
      this.exibirSpinning ==
        !(this.secaoFiltroLista.length > 0 && this.orgaoFiltroLista.length > 0);
    });
  }
  getItemAutocompleteProposicao(ev: any) {
    if (ev.target.value.length >= 3) {
    }
  }
  fonteDadosChange(fonte) {
    if (this.radar.Tags.length > 0) this.preVisualizar();
  }
  formularioValido() {
    return this.radar.Tags && this.radar.Tags.length > 0;
  }
  carregarDiarioRecursivo() {
    var tag = this.tagsPopDou.pop();
    if (tag) this.presentToast('Carregando ' + tag + '...');
    this.diarioOficialService
      .listarPorTag(tag, this.buildUrl(this.radar))
      .then((data: any[]) => {
        if (tag) this.presentToast('Tag ' + tag + ' carregada com sucesso...');
        this.exibirSpinning = false;
        data = data.map((x) => {
          x.Tag = tag;
          return x;
        });
        this.diarioListaBkp = this.diarioListaBkp.concat(data as any[]);
        this.diarioLista = this.diarioListaBkp.sort(function (a: any, b: any) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return (new Date(b.Data) as any) - (new Date(a.Data) as any);
        });
      })
      .finally(() => {
        if (this.tagsPopDou.length > 0 && !this.exibirSpinning) {
          this.diarioListaPaginacao = this.diarioLista.slice(
            0,
            this.paginaAtualDiario
          );
          this.carregarDiarioRecursivo();
        } else {
          this.carregandoDOU = false;
          this.diarioLista = this.diarioListaBkp.sort(function (
            a: any,
            b: any
          ) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (new Date(b.Data) as any) - (new Date(a.Data) as any);
          });
          this.diarioLista = this.diarioListaBkp;
          var secaoFiltro = this.radar.FiltroLista.find(
            (x) => x.Tag === tag
          )?.Secao;
          var orgaoFiltro = this.radar.FiltroLista.find(
            (x) => x.Tag === tag
          )?.Orgao;
          this.diarioLista = this.diarioLista.filter(
            (x) =>
              (x.Orgao === orgaoFiltro || orgaoFiltro === 'Todos os orgãos') &&
              (x.Secao === secaoFiltro || secaoFiltro === 'Todas as seções')
          );
          this.diarioListaPaginacao = this.diarioLista.slice(
            0,
            this.paginaAtualDiario
          );
        }
      });
  }
  diarioClick(diario) {
    window.open(diario.Url, '_system');
  }
  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  async preVisualizar() {
    if (this.radar.AssuntoItem) {
      if (this.radar.AssuntoItem.indexOf(',') >= 0) {
        this.radar.AssuntoItem.split(',').forEach((assunto) => {
          this.radar.Tags.push(assunto.trim());
          this.radar.FiltroLista.push({
            Tag: assunto.trim(),
            Orgao: this.filtroOrgaoDou,
            Secao: this.filtroSecaoDou,
          });
        });
      } else {
        this.radar.Tags.push(this.radar.AssuntoItem.trim());
        this.radar.FiltroLista.push({
          Tag: this.radar.AssuntoItem.trim(),
          Orgao: this.filtroOrgaoDou,
          Secao: this.filtroSecaoDou,
        });
      }
      this.radar.AssuntoItem = '';
      this.filtroSecaoDou = 'Todas as seções';
      this.filtroOrgaoDou = 'Todos os orgãos';
    }

    this.carregarDiarioOficial();
  }
  excluirAssunto(assunto) {
    this.zone.run(() => {
      this.radar.Tags = this.radar.Tags.filter((x) => x !== assunto);
      this.radar.FiltroLista = this.radar.FiltroLista.filter(
        (x) => x.Tag !== assunto
      );
      this.preVisualizar();
    });
  }
  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      position: 'bottom',
      color: 'primary',
      cssClass: 'toast-radar',
    });
    toast.onDidDismiss().then((e) => {});
    toast.present();
  }
  async filtrarAssunto(assunto) {
    if (!this.filtroAssunto) {
      const actionSheet = await this.actionSheetController.create({
        cssClass: '',
        buttons: [
          {
            text: 'Filtrar por ' + assunto,
            icon: 'funnel-outline',
            handler: () => {
              this.zone.run(() => {
                this.filtroAssunto = assunto;
                this.radar.TagsBkp = this.radar.Tags;
                this.radar.Tags = this.radar.Tags.filter((x) => x === assunto);
                this.preVisualizar();
              });
            },
          },
          {
            text: 'Excluir',
            role: 'destructive',
            icon: 'trash',
            handler: () => {
              this.excluirAssunto(assunto);
            },
          },
          {
            text: 'Cancelar',
            icon: 'close',
            role: 'cancel',
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    } else {
      this.filtroAssunto = false;
      this.radar.Tags = this.radar.TagsBkp;
      this.radar.TagsBkp = undefined;
      this.preVisualizar();
    }
  }
  getQtdDiarioTag(tag) {
    try {
      return this.diarioListaBkp.filter((x) => x.Tag == tag).length;
    } catch (error) {
      return 0;
    }
  }
  async salvarClick() {
    if (this.formularioValido()) {
      if (!this.radar.Titulo) this.radar.Titulo = this.radar.Tags[0];
      if (!this.radar.Titulo) this.radar.Titulo = 'Radar sem título';
      this.exibirLoading('Carregando...');
      if (this.radar.DOEDF) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOEDF',
        });
      }
      if (this.radar.DOEAL) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOEAL',
        });
      }
      if (this.radar.DOEBA) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOEBA',
        });
      }
      if (this.radar.DOERS) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOERS',
        });
      }
      if (this.radar.DOERJ) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOERJ',
        });
      }
      if (this.radar.DOEGO) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOEGO',
        });
      }
      if (this.radar.DOETO) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOETO',
        });
      }
      if (this.radar.DOEMT) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOEMT',
        });
      }
      if (this.radar.DOEMA) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOEMA',
        });
      }
      if (this.radar.DOESP) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOESP',
        });
      }
      if (this.radar.DOEMUNSP) {
        this.radar.FiltroLocalLista.push({
          Nome: 'DOEMUNSP',
        });
      }
      const func = this.radar.Id
        ? this.radarDiarioMonitoramentoService.alterar(this.radar)
        : this.radarDiarioMonitoramentoService.cadastrar(this.radar);
      func.then(async (data: any) => {
        this.vibration.vibrate(2000);
        const toast = await this.toastController.create({
          message: '🎉 Radar salvo com sucesso!',
          duration: 3000,
          color: 'success',
        });
        toast.present();
        localStorage.setItem('RADAR_MODIFICADO', 'true');
        try {
          this.zone.run(() => {
            this.usuarioService
              .obterPlano(this.usuarioService.get().Id)
              .then((data) => {
                this.plano = data;
                if (!this.plano) this.assinaturaService.assinar();
              });
          });
        } catch (error) {}
        this.fechar();
        this.ocultarLoading();
      });
    }
  }
  fechar() {
    this.modalController.dismiss();
  }
}
