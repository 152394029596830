import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { EmentaComponent } from '../ementa/ementa.component';

@Component({
  selector: 'app-icone-inteiro-teor',
  templateUrl: './icone-inteiro-teor.component.html',
  styleUrls: ['./icone-inteiro-teor.component.scss'],
})
export class IconeInteiroTeorComponent implements OnInit {
  @Input()
  texto: any;
  constructor(public popoverController: PopoverController) { }

  ngOnInit() {}

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: EmentaComponent,
      cssClass: 'modal-pontuacao',
      event: ev,
      componentProps: {
        ementa: this.texto,
      }
    });
    await popover.present();
  }

}
