import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';
// import { promise } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class PrazoService extends ApiConfig {
  constructor(private http: HttpClient) {
    super();
  }
  listar() {
    return new Promise(async (resolve) => {
      this.http.get('https://api.legislapp.com.br/Prazo/ListarGet',
        {})
        .subscribe(data => {
          resolve(data);
        }, error => {
          
          
        });
    });
  }
}
