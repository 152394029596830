import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, IonicModule } from '@ionic/angular';
import { PageBaseService } from '../util/page-base.service';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-ordem-dia',
    templateUrl: './ordem-dia.page.html',
    styleUrls: ['./ordem-dia.page.scss'],
    imports: [
        IonicModule,
        NgFor,
        NgIf,
    ],
})
export class OrdemDiaPage extends PageBaseService implements OnInit {
  @Input()
  listaOrdemDia: any;
  constructor(
    private modalController: ModalController,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
  ) {

    super(loadingController, alertCtrl, null, null);

  }

  ngOnInit() {}

  ordemDiaClick(ordemDia) {
    window.open(ordemDia.Url,
      '_blank'
  );
  }
  fechar() {
    this.modalController.dismiss();
  }
}
