import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClienteService } from '../api/cliente-service.service';
import { UsuarioService } from '../api/usuario.service';
import { ModalController, ToastController, IonicModule } from '@ionic/angular';
import { Subject } from 'rxjs';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-meus-clientes-edicao',
    templateUrl: './meus-clientes-edicao.page.html',
    styleUrls: ['./meus-clientes-edicao.page.scss'],
    imports: [
        NgIf,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
    ],
})
export class MeusClientesEdicaoPage implements OnInit {

  @Input()
  objEdicao: any;
  ionicForm: any;
  @Input()
  subject: Subject<any> = new Subject<any>();
  constructor(public formBuilder: FormBuilder, private toastController: ToastController, private modalController: ModalController, private clienteService: ClienteService, private usuarioService: UsuarioService) { }
  ngOnInit() {
    this.ionicForm = this.formBuilder.group({
      nome: [this.objEdicao?.Nome, [Validators.required, Validators.minLength(3)]]
    });
  }
  salvar() {
    if (this.ionicForm.valid) {
      this.usuarioService
        .obterPlano(this.usuarioService.get().Id)
        .then((data: any) => {
          var model = {
            Id: undefined,
            Nome: this.ionicForm.controls["nome"].value,
            IdPlano: data.Id
          };
          var funcao;
          if (this.objEdicao?.Id) {
            model.Id = this.objEdicao.Id;
            funcao = this.clienteService.alterar(model);
          }
          else
            funcao = this.clienteService.inserir(model);
          funcao.then((data) => {
            this.presentToast('🎉 Cliente salvo com sucesso!');
            this.fechar();
            this.subject.next("");
          });
        });
    }
  }
  async presentToast(text: string) {
    const toast = await this.toastController.create({
      message: '',
      duration: 10000,
      position: 'top',
      color: 'success',
      cssClass: '',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-done-outline',
          text: ' ' + text
        },
      ],
    });
    toast.onDidDismiss().then((e) => { });
    toast.present();
  }
  deletarMembroPlano(membro: any) {

  }
  fechar() {
    this.modalController.dismiss({});
  }
}
