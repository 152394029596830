import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, IonicModule } from '@ionic/angular';
import { ParlamentarService } from '../api/parlamentar.service';
import { ParlamentarComponent } from '../parlamentar/parlamentar.component';
import { PageBaseService } from '../util/page-base.service';

@Component({
    selector: 'app-criterio-ranking',
    templateUrl: './criterio-ranking.page.html',
    styleUrls: ['./criterio-ranking.page.scss'],
    imports: [IonicModule],
})
export class CriterioRankingPage extends PageBaseService implements OnInit {
  @Input()
  comissao: any;
  constructor(public alertCtrl: AlertController, private modalController: ModalController, private parlamentarService: ParlamentarService, protected loadingController: LoadingController) {
    super(loadingController, alertCtrl, null, null);
  }

  ngOnInit() {

  }

  fechar() {
    this.modalController.dismiss({
    });  
  }
}
