// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll-items {
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: scroll !important;
  overflow-y: hidden;
}

.scroll-items ion-col {
  display: flex;
  flex-wrap: nowrap;
  min-width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/story/story.component.scss","webpack://./../../../../../../Luar%20Faria/source/repos/luarfaria/Legislapp/Legislapp.Mobile/src/app/story/story.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,iBAAA;EACA,6BAAA;EACA,kBAAA;ACCF;;ADCA;EACI,aAAA;EACA,iBAAA;EACA,eAAA;ACEJ","sourcesContent":[".scroll-items {\r\n  justify-content: flex-start; // changed\r\n  flex-wrap: nowrap;\r\n  overflow-x: scroll !important;\r\n  overflow-y: hidden;\r\n}\r\n.scroll-items ion-col { // added\r\n    display: flex;\r\n    flex-wrap: nowrap;\r\n    min-width: 50px;\r\n}",".scroll-items {\n  justify-content: flex-start;\n  flex-wrap: nowrap;\n  overflow-x: scroll !important;\n  overflow-y: hidden;\n}\n\n.scroll-items ion-col {\n  display: flex;\n  flex-wrap: nowrap;\n  min-width: 50px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
