import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  LoadingController,
  AlertController,
  ModalController,
  Platform,
  IonModal,
} from '@ionic/angular';
import { AssinaturaService } from '../api/assinatura.service';
import { ComissaoService } from '../api/comissao.service';
import { ProjetoService } from '../api/projeto.service';
import { UsuarioService } from '../api/usuario.service';
import { ComissaoPage } from '../comissao/comissao.page';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.page.html',
  styleUrls: ['./agenda.page.scss'],
})
export class AgendaPage extends PageBaseService implements OnInit {
  @Input()
  agenda: any;
  plano: any;
  constructor(
    private usuarioService: UsuarioService,
    private projetoService: ProjetoService,
    public alertCtrl: AlertController,
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
    private assinaturaService: AssinaturaService,
    private comissaoService: ComissaoService,
    protected loadingController: LoadingController,
  ) {
    super(loadingController, alertCtrl, null, null);
  }
  
  formatarHorario(input: string) {
    const parts = input.split(':');
    return parts[0] + ':' + parts[1];
  }
  ngOnInit() {
    try {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {
          this.plano = data;
          if (!this.plano) this.assinaturaService.assinar();
        });
    } catch (error) {}
  }
  async proposicaoClick(proposicao) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({
        Id: proposicao.Id,
        IdUsuario: this.usuarioService.get() ? this.usuarioService.get().Id : 0,
      })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }
  onImgError(item) {
    item.UrlFotoAutor = '/assets/imagem-perfil.jpg';
  }
  comissaoClick(comissao: any) {
    try {
      this.usuarioService
        .obterPlano(this.usuarioService.get().Id)
        .then((data) => {
          this.plano = data;
          if (!this.plano) this.assinaturaService.assinar();
        });
    } catch (error) {}
    this.exibirLoading('Carregando...');
    this.comissaoService.obter({ Id: comissao.Id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ComissaoPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          comissao: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }
 
  verProjeto(idProjeto) {
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: idProjeto, IdUsuario: this.usuarioService.get().Id })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }

  transformUrlSafe(url) {
    if (url.indexOf('embed') >= 0) return this.sanitizer.bypassSecurityTrustResourceUrl(url.split(' ')[0]);
    url = 'https://www.youtube.com/embed/' + url.split('?v=')[1];
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  fechar() {
    this.modalController.dismiss({});
  }
}
