import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssinaturaModule } from '../assinatura/assinatura.module';
import { StoryComponent } from './story.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MeuPlanoPageModule } from '../meu-plano/meu-plano.module';



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [StoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AssinaturaModule,
    MeuPlanoPageModule
  ],
  exports:[
    StoryComponent
  ]
})
export class StoryModule { }
