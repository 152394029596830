import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { EmentaComponent } from '../ementa/ementa.component';

@Component({
    selector: 'app-icone-ementa',
    templateUrl: './icone-ementa.component.html',
    styleUrls: ['./icone-ementa.component.scss'],
})
export class IconeEmentaComponent implements OnInit {
  @Input()
  ementa: any;
  constructor(public popoverController: PopoverController) { }

  ngOnInit() {}

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: EmentaComponent,
      cssClass: 'modal-pontuacao',
      event: ev,
      componentProps: {
        ementa: this.ementa,
      }
    });
    await popover.present();
  }

}
