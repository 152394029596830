import { Component, OnInit, ViewChild } from '@angular/core';
import { Share } from '@capacitor/share';
import { ModalController } from '@ionic/angular';
import { DiarioOficialService } from '../api/diario-oficial.service';
import { ProjetoService } from '../api/projeto.service';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { UsuarioService } from '../api/usuario.service';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { RadarEdicaoPage } from '../radar-edicao/radar-edicao.page';
import { NgFor, NgIf, DatePipe } from '@angular/common';
import { SegmentoEstadosComponent } from '../segmento-estados/segmento-estados.component';

@Component({
    selector: 'app-diario-oficial-usuario',
    templateUrl: './diario-oficial-usuario.component.html',
    styleUrls: ['./diario-oficial-usuario.component.scss'],
    imports: [
        NgFor,
        SegmentoEstadosComponent,
        NgIf,
        DatePipe,
    ],
})
export class DiarioOficialUsuarioComponent implements OnInit {
  exibeSpinner: boolean;
  estadoSelecionado: string;
  diarioLista: any[];
  diarioListaBkp: any[];
  public dataSelecionadaStr;
  public date;
  dataSelecionada: Date;
  diaSemanaLista: string[];
  diaMesLista: any[];
  indexDiaAtual: any;
  mesAtual: any;
  @ViewChild('datePicker', { static: true }) datePicker;
  exibeCalendario = false;

  constructor(
    private diarioOficialService: DiarioOficialService,
    private usuarioService: UsuarioService,
    private projetoService: ProjetoService,
    private radarMonitoramentoService: RadarMonitoramentoService,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.date = {};
    this.carregarCalendarioUi();
    this.carregarLista();
  }
  openDatePicker() {
    this.exibeCalendario = true;
  }
  diarioClick(diario) {
    window.open(diario.Url, '_system');
  }

  shareDiario(diario) {
    var texto = `${diario.Texto}`;
    var link = diario.Url;
    Share.share({
      title: diario.Titulo,
      text: texto.replace('<b><mark>', '*').replace('</mark></b>', '*'),
      url: link,
      dialogTitle: 'Compartilhe o legislativo onde quiser!',
    })
      .then((arg) => {})
      .catch((error) => {});
  }

  verProjeto(idProjeto) {
    this.projetoService
      .obter({ Id: idProjeto, IdUsuario: this.usuarioService.get().Id })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        return await modal.present();
      });
  }

  diaChanged(ev: any) {
    this.dataSelecionada.setDate(
      this.dataSelecionada.getDate() -
        (this.indexDiaAtual - parseInt(ev.detail.value))
    );
    this.indexDiaAtual = ev.detail.value;
    this.carregarLista();
  }

  carregarCalendarioUi() {
    this.dataSelecionada = new Date();
    if (this.dataSelecionadaStr)
      this.dataSelecionada = new Date(this.dataSelecionadaStr);
    this.diaSemanaLista = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
    this.diaMesLista = [];
    var d = new Date(this.dataSelecionada);
    d.setDate(d.getDate() - d.getDay());

    for (let index = 0; index < 7; index++) {
      this.diaMesLista.push(d.getDate().toString());
      d.setDate(d.getDate() + 1);
    }
    this.indexDiaAtual = this.dataSelecionada.getDay();
    this.mesAtual = this.dataSelecionada.toLocaleDateString('default', {
      month: 'long',
    });
    // this.exibeCalendario = false;
  }
  fecharCalendario() {
    this.exibeCalendario = false;
  }
  dataCalendarioChange(event) {
    this.dataSelecionadaStr = event.detail.value;
    this.carregarCalendarioUi();
    this.carregarLista();
    this.exibeCalendario = false;
  }
  filtroEstado(event, that) {
    event = event.replace('estado', '');
    that.estadoSelecionado = event;
    that.diarioLista = that.diarioListaBkp.filter((x) => x.UF == event);
  }
  getSecao(diarioOficial) {
    // "515", "529", "353", "600"
    if (diarioOficial.Url.indexOf('515') > -1) return 'Seção 1';
    else if (diarioOficial.Url.indexOf('529') > -1) return 'Seção 2';
    else if (diarioOficial.Url.indexOf('350') > -1) return 'Seção 3';
    else if (diarioOficial.Url.indexOf('600') > -1) return 'Edição Extra';
  }

  carregarLista() {
    this.exibeSpinner = true;
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let date = new Date(this.dataSelecionada.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
    this.diarioOficialService
      .listarPorData(date.split('T')[0], this.usuarioService.get().Id)
      .then((data) => {
        this.diarioLista = data as any[];
        this.diarioListaBkp = data as any[];
        this.diarioLista.map((x) => {
          x.Url = x.Url.replace('http:', 'https:');
          return x;
        });
        if (this.estadoSelecionado) this.filtroEstado(this.estadoSelecionado, this);
        this.exibeSpinner = false;
      });
  }

  async verRadarClick(idRadar) {
    this.modalController.dismiss();
    this.radarMonitoramentoService
      .listar(this.usuarioService.get().Id)
      .then(async (data: any[]) => {
        var radar = data.find((x) => x.Id == idRadar);
        const modal = await this.modalController.create({
          component: RadarEdicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            radar,
          },
        });
        return await modal.present();
      });
  }
}
