import { Component, Input, OnInit } from '@angular/core';
import { NgIf, NgFor } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'app-story',
    templateUrl: './story.component.html',
    styleUrls: ['./story.component.scss'],
    imports: [
        NgIf,
        IonicModule,
        NgFor,
    ],
})
export class StoryComponent implements OnInit {
  @Input()
  stakeholderList: any[];

  @Input()
  scope: any[];

  @Input()
  stakeHolderClick: any;
  nomeStakeholderSelecionado: any;
  constructor() {}
  click(stakeholder) {
    if (this.nomeStakeholderSelecionado == stakeholder.Nome) {
      this.nomeStakeholderSelecionado = '';
    } else {
      this.nomeStakeholderSelecionado = stakeholder.Nome;
    }
    this.stakeHolderClick(this.nomeStakeholderSelecionado , this.scope);
  }
  ngOnInit() {}
  popoverDidPresent($event: any) {
    // setTimeout(() => {
    //   $event.target.dismiss();
    // }, 2000);
  }

  onImgError(item) {
    if (!item.Nome) item.Nome = item.Autor;
    if (item.Nome == 'Senado Federal') {
      item.Imagem = '/assets/logo-senado.png';
    } else if (
      item.Nome == 'Câmara dos Deputados' ||
      item.Nome == 'Câmara Dos Deputados'
    ) {
      item.Imagem = '/assets/logo-camara.png';
    } else if (
      item.Nome == 'Poder Executivo' ||
      item.Nome == 'Presidência da República'
    ) {
      item.Imagem = '/assets/poderexecutivo.png';
    } else if (
      item.Nome &&
      item.Nome.toUpperCase().indexOf('COMISSÃO') > -1
    ) {
      item.Imagem = '/assets/comissoes.png';
    } else {
      item.Imagem = '/assets/imagem-perfil.jpg';
    }
  }
}
