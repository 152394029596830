import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { Clipboard } from '@capacitor/clipboard';

@Component({
  selector: 'app-icone-copiar',
  templateUrl: './icone-copiar.component.html',
  styleUrls: ['./icone-copiar.component.scss'],
})
export class IconeCopiarComponent implements OnInit {
  @Input()
  texto: string;
  constructor(
    public popoverController: PopoverController,
    private toastController: ToastController
  ) {}

  ngOnInit() {}

  async iconeClick(ev: any) {
    await Clipboard.write({
      string: this.texto,
    });
    const toast = await this.toastController.create({
      color: 'success',
      message: this.texto + ' copiado para a memória!',
      duration: 2000,
    });
    toast.present();
  }
}
