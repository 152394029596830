import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrdemDiaPageRoutingModule } from './ordem-dia-routing.module';

import { OrdemDiaPage } from './ordem-dia.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        OrdemDiaPageRoutingModule,
        OrdemDiaPage
    ]
})
export class OrdemDiaPageModule {}
