import { Component, NgZone, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, ToastController, IonicModule } from '@ionic/angular';
import { ConfiguracaoNotificacaoService } from '../api/configuracao-notificacao.service';
import { UsuarioService } from '../api/usuario.service';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';
import { Clipboard } from '@capacitor/clipboard';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-configuracao-notificacao',
    templateUrl: './configuracao-notificacao.page.html',
    styleUrls: ['./configuracao-notificacao.page.scss'],
    imports: [IonicModule, FormsModule],
})
export class ConfiguracaoNotificacaoPage extends PageBaseService implements OnInit {
  configuracaoNotificacaoVM: any;

  constructor(private menuService: MenuService, private usuarioService: UsuarioService, private modalController: ModalController, private configuracaoNotificacaoService: ConfiguracaoNotificacaoService, protected loadingController: LoadingController, protected alertCtrl: AlertController,
    private zone: NgZone, private toastController:ToastController) {
    super(loadingController, alertCtrl, null, null);
  }

  ngOnInit() {
    this.carregarLista();
  }

  async configuracaoClick(habilitado, tipoPush) {
    if (tipoPush === 'Whatsapp' && habilitado) {
      const alertComponent = await this.alertCtrl.create({
        cssClass: 'modal-pontuacao',
        header: 'Por favor atualize seu número de celular',
        inputs: [
          {
            name: 'celular',
            type: 'text',
            placeholder: 'Ex 61999999999',
          }],
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            }
          }, {
            text: 'Ok',
            handler: (e) => {
              this.usuarioService.atualizarCelular(this.usuarioService.get().Id, e.celular).then(async ()=> {
                const toast = await this.toastController.create({
                  message: '📰 Enviamos no seu Whatsapp uma mensagem para concluir a configuração!',
                  duration: 5000,
                  position: 'bottom',
                  color: 'primary',
                  cssClass: 'toast-radar',
                });
                toast.onDidDismiss().then((e) => { });
                toast.present();
              });
            }
          }
        ]
      });
      alertComponent.present();
    }
    if (tipoPush === 'Telegram' && habilitado) {
      this.usuarioService.obterTokenTelegram(this.usuarioService.get().Id).then(async data => {
        await Clipboard.write({
          string: data as string,
        });
        const alertComponent = await this.alertCtrl.create({
          cssClass: 'modal-pontuacao',
          header: 'Clique em OK para copiar o código e cole no bot do Telegram',
          inputs: [
            {
              name: 'token',
              type: 'text',
              placeholder: 'Token Telegram',
              value: data
            }],
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
              }
            }, {
              text: 'Ok',
              handler: () => {
                window.open('https://telegram.me/LegislappBot?start=' + data, '_system');
              }
            }
          ]
        });
        alertComponent.present();
        this.ocultarLoading();
      });

    }
    this.exibirLoading('Carregando...');
    this.configuracaoNotificacaoService.alterar({ Habilitado: habilitado, TipoPush: tipoPush, IdUsuario: this.usuarioService.get().Id }).then(data => {
      this.configuracaoNotificacaoVM = data as any[];
      this.ocultarLoading();
    });

  }

  fechar() {
    this.modalController.dismiss({
    });
  }

  carregarLista() {
    this.exibirLoading('Carregando...');
    this.configuracaoNotificacaoService.obter(this.usuarioService.get().Id).then(data => {
      this.configuracaoNotificacaoVM = data;
      this.ocultarLoading();
    });
  }

}
