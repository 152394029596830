import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TypeaheadPage } from './typeahead.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TypeaheadPage,
    ],
    exports: [
        TypeaheadPage
    ]
})
export class TypeaheadPageModule {}