import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypeaheadPageModule } from '../typeahead/typeahead.module';
import { AgendaParlamentarComponentPage } from './agenda-parlamentar-component.page';

@NgModule({
    imports: [
        CommonModule,
        TypeaheadPageModule,
        AgendaParlamentarComponentPage
    ],
    exports: [
        AgendaParlamentarComponentPage
    ]
})
export class AgendaParlamentarComponentPageModule { }
