import { Injectable } from '@angular/core';
import { ApiConfig } from './api.config';
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService extends ApiConfig {
  planoData: Object;
  constructor(private http: HttpClient, private platform: Platform) {
    super();
  }
  emailCadastrado(usuario) {
    return new Promise(async (resolve) => {
      try {
        this.http
          .post('https://api.legislapp.com.br/Usuario/EmailCadastrado', usuario)
          .subscribe(
            (data) => {
              resolve(data);
            },
            (error) => {
              // alert(
              //   //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
              // );
            }
          );
      } catch (error) {
        alert('Ocorreu um erro ao enviar o código de verificação: ' + error);
      }
    });
  }

  login(usuario) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Usuario/Login', usuario)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }

  ingressarConvite(model) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Usuario/IngressarConvite', model)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }

  criar(usuario) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Usuario/Criar', usuario)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }

  visualizarNotificacao(idNotificacao) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Usuario/VisualizarNotificacao', {
          IdNotificacao: idNotificacao,
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //   //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  set(usuario) {
    localStorage.setItem('TUTORIAL', 'true');
    localStorage.setItem('USUARIO', JSON.stringify(usuario));
  }

  get() {
    var usuario = localStorage.getItem('USUARIO');
    if (usuario) return JSON.parse(usuario);
    else return null;
  }

  atualizarImagem(usuario) {
    return new Promise(async (resolve) => {
      this.http
        .post(this.URL_API + 'Usuario/AtualizarImagem', usuario)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            // alert(
            //   //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            // );
          }
        );
    });
  }

  atualizarToken(usuario) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/PushNotification/Atualizar',
          usuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            alert(
              'Ocorreu um erro ao Atualizar o push.' + JSON.stringify(error)
            );

            alert(
              'Ocorreu um erro ao Atualizar o push.' + JSON.stringify(usuario)
            );
          }
        );
    });
  }
  listarUsuariosPlano(IdUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Usuario/ListarUsuariosPlano?IdUsuarioAdmin=' +
            IdUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            alert('Ocorreu um erro ao obter o token telegram.');
          }
        );
    });
  }
  atualizarCelular(IdUsuario, celular) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Usuario/AtualizarCelular?id=' +
            IdUsuario +
            '&celular=' +
            celular
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            alert('Ocorreu um erro ao obter o token telegram.');
          }
        );
    });
  }
  obterTokenTelegram(IdUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Usuario/obterTokenTelegram?IdUsuario=' +
            IdUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            alert('Ocorreu um erro ao obter o token telegram.');
          }
        );
    });
  }
  notificarIngressoRadar(email, idRadar) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Usuario/NotificarIngressoRadar?email=' +
            email +
            '&idRadar=' +
            idRadar
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {}
        );
    });
  }
  criarPedidoAssinatura(IdUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Usuario/CriarPedidoAssinatura?idUsuario=' +
            IdUsuario
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            alert('Ocorreu um erro ao obter o token telegram.');
          }
        );
    });
  }
  obterPlano(IdUsuario) {
    if (this.platform.is('ios')) {
      this.planoData = { Nome: 'Legislapp', QtdUsuario: 1 };
    }
    if (this.planoData)
      return new Promise(async (resolve) => {
        resolve(this.planoData);
      });

    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Usuario/ObterPlanoCompletoGet?idUsuario=' +
            IdUsuario
        )
        .subscribe(
          (data) => {
            this.planoData = data;
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }

  esqueciSenha(email) {
    return new Promise(async (resolve) => {
      this.http
        .get(
          'https://api.legislapp.com.br/Usuario/RedefinirSenha?email=' + email
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }

  atualizarSenha(usuario) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Usuario/AtualizarSenha', usuario)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }
  somenteLeitura(model) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Usuario/SomenteLeitura', model)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }

  permissao(email) {
    return new Promise(async (resolve) => {
      this.http
        .get('https://api.legislapp.com.br/Usuario/Permissao?email=' + email)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }

  ingressarRadar(IngressarRadarModel) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Usuario/IngressarRadar',
          IngressarRadarModel
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }
  egressarRadar(IngressarRadarModel) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Usuario/EgressoRadar',
          IngressarRadarModel
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }
  obterDataPlano(IdUsuario) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Usuario/ObterDataPlano', {
          IdUsuario,
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }
  removerPlano(convitePlano) {
    return new Promise(async (resolve) => {
      this.http
        .post('https://api.legislapp.com.br/Usuario/RemoverPlano', convitePlano)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }
  convidarPlano(convitePlano) {
    return new Promise(async (resolve) => {
      this.http
        .post(
          'https://api.legislapp.com.br/Usuario/ConvidarPlano',
          convitePlano
        )
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            //alert(
            //'Ocorreu um erro ao se conectar ao servidor, verifique sua conexão e tente novamente.'
            //);
          }
        );
    });
  }
}
