import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss'],
})
export class StoryComponent implements OnInit {
  @Input()
  stakeholderList: any[];

  @Input()
  scope: any[];

  @Input()
  stakeHolderClick: any;
  nomeStakeholderSelecionado: any;
  constructor() {}
  click(stakeholder) {
    this.nomeStakeholderSelecionado = stakeholder.Nome;
    this.stakeHolderClick(stakeholder.Nome, this.scope);
  }
  ngOnInit() {}
  popoverDidPresent($event: any) {
    setTimeout(() => {
      $event.target.dismiss();
    }, 2000);
  }
}
