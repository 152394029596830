import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { RadarEdicaoPageRoutingModule } from './radar-edicao-routing.module';

import { RadarEdicaoPage } from './radar-edicao.page';
import { IconeEmentaModule } from '../icone-ementa/icone-ementa.module';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { ContadorProposicaoModule } from '../contador-proposicao/contador-proposicao.module';
import { PipesModule } from '../util/pipes.module';
import { AgendaComponentPageModule } from '../agenda-component/agenda-component.module';
import { AssinaturaModule } from '../assinatura/assinatura.module';
import { IconeInteiroTeorModule } from '../icone-inteiro-teor/icone-inteiro-teor.module';
import { TypeaheadPageModule } from '../typeahead/typeahead.module';
import { IconeCopiarModule } from '../icone-copiar/icone-ementa.module';
import { StoryModule } from '../story/story.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    Ng2GoogleChartsModule,
    IconeEmentaModule,
    IconeInteiroTeorModule,
    IconeCopiarModule,
    AgendaComponentPageModule, 
    RadarEdicaoPageRoutingModule,
    ContadorProposicaoModule,
    AssinaturaModule,
    PipesModule,
    TypeaheadPageModule,
    StoryModule
  ],
  declarations: [RadarEdicaoPage],
  exports:[
    RadarEdicaoPage
  ]
})
export class RadarEdicaoPageModule {}
