import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { IonicModule } from '@ionic/angular';
import { ParlamentarComponent } from './parlamentar.component';
import { IconeEmentaModule } from '../icone-ementa/icone-ementa.module';
import { AssinaturaModule } from '../assinatura/assinatura.module';
import { ParlamentarPageRoutingModule } from './parlamentar-routing.module';
import { PipesModule } from '../util/pipes.module';
import { AgendaParlamentarComponentPageModule } from '../agenda-parlamentar-component/agenda-parlamentar-component.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        Ng2GoogleChartsModule,
        IconeEmentaModule,
        ParlamentarPageRoutingModule,
        AssinaturaModule,
        PipesModule,
        AgendaParlamentarComponentPageModule,
        ParlamentarComponent
    ]
})
export class ParlamentarPageModule {}


