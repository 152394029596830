import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-segmento-estados',
  templateUrl: './segmento-estados.component.html',
  styleUrls: ['./segmento-estados.component.scss'],
})
export class SegmentoEstadosComponent implements OnInit {
  @Input() legislativo;
  @Input() filtrar;
  @Input() scope;
  segmento: string;
  constructor() {}

  ngOnInit() {
    this.segmento = 'todos';
  }

  doFiltrar($event) {
    this.filtrar($event.detail.value, this.scope);
  }
}
