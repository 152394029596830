import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Platform,
  ModalController,
  LoadingController,
  AlertController,
  ActionSheetController,
  ToastController,
} from '@ionic/angular';
import { NewsletterService } from '../api/newsletter.service';
import { ParlamentarService } from '../api/parlamentar.service';
import { PrazoService } from '../api/prazo.service';
import { ProjetoService } from '../api/projeto.service';
import { UsuarioService } from '../api/usuario.service';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';
import { toastController } from '@ionic/core';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';

@Component({
  selector: 'app-newsletter-lista',
  templateUrl: './newsletter-lista.page.html',
  styleUrls: ['./newsletter-lista.page.scss'],
})
export class NewsletterListaPage extends PageBaseService {
  listaNewsletter: any;
  segmento: string;
  newsletterEdicao: any;
  processando = false;
  plano: any;
  constructor(
    private vibration: Vibration,
    private menuService: MenuService,
    public platform: Platform,
    public modalController: ModalController,
    private newsletterService: NewsletterService,
    private prazoService: PrazoService,
    private usuarioService: UsuarioService,
    private parlamentarService: ParlamentarService,
    public route: ActivatedRoute,
    protected loadingController: LoadingController,
    protected alertCtrl: AlertController,
    private zone: NgZone,
    protected router: Router,
    private actionSheetController: ActionSheetController,
    private toastController: ToastController
  ) {
    super(loadingController, alertCtrl, platform, router);
  }

  ngOnInit() {
    this.usuarioService
      .obterPlano(this.usuarioService.get().Id)
      .then((plano) => {
        this.plano = plano;
      });
    this.carregarLista();
  }
  async newsletterClick(newsletter) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Newsletter de monitoramento legislativo',
      cssClass: 'modal-pontuacao',
      buttons: [
        {
          text: 'Ver/Editar Newsletter',
          icon: 'eye-outline',
          handler: () => {
            this.newsletterEdicao = newsletter;
          },
        },
        {
          text: 'Baixar',
          icon: 'download-outline',
          handler: () => {
            this.baixar(newsletter);
          },
        },
        {
          text: 'Deletar',
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            this.newsletterService.deletar(newsletter.Id).then(() => {
              this.carregarLista();
            });
          },
        },
        {
          text: 'Cancelar',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await actionSheet.present();
  }
  async presentToast(text: string) {
    const toast = await this.toastController.create({
      message: '',
      duration: 10000,
      position: 'top',
      color: 'success',
      cssClass: '',
      buttons: [
        {
          side: 'start',
          icon: 'checkmark-done-outline',
          text: ' ' + text,
        },
      ],
    });
    toast.onDidDismiss().then((e) => {});
    toast.present();
  }

  baixar(newsletter) {
    if (this.processando) {
      this.presentToast('🗞️ A exportação já está em andamento!');
      return;
    }
    this.exibirLoading(
      'Gerando a Newsletter... te avisaremos quando estiver pronta pra download.'
    );
    this.vibration.vibrate([2000, 1000, 1000]);
    var that = this;
    setTimeout(() => {
      that.ocultarLoading();
    }, 5000);
    this.newsletterService
      .gerar(newsletter.Id, this.plano.Nome)
      .then((data: any) => {
        this.processando = true;
        setTimeout(() => {
          that.ocultarLoading();
        }, 5000);
        this.presentToast('🗞️ Gerando newsletter...');

        // var downloadURL = window.URL.createObjectURL(data);
        // var link = document.createElement('a');
        // link.href = downloadURL;
        // link.download = 'Newsletter Legislapp.pdf';
        // link.click();
        // this.ocultarLoading();
      });
  }

  cadastroClick() {
    this.newsletterEdicao = {};
  }

  cadastrar() {
    this.exibirLoading('Gerando Newsletter...');
    if (!this.newsletterEdicao.IdUsuario) {
      this.newsletterEdicao.IdUsuario = this.usuarioService.get().Id;
      this.newsletterEdicao.EnvioAutomatico = true;
      this.newsletterEdicao.EmailListaStr = this.usuarioService.get().Email;
    }
    this.newsletterService.criar(this.newsletterEdicao).then((data) => {
      this.listaNewsletter = data;
      this.ocultarLoading();
      this.newsletterEdicao = undefined;
      this.carregarLista();
    });
  }
  fechar() {
    this.modalController.dismiss({});
  }
  carregarLista($event = undefined) {
    this.exibirLoading('Carregando...');
    this.newsletterService.listar(this.usuarioService.get().Id).then((data) => {
      this.listaNewsletter = data;
      this.ocultarLoading();
    });
  }
  ionViewWillEnter() {
    this.menuService.publish('Newsletter');
    this.menuService.publishBack(false);

    this.menuService.subjectBack.subscribe((exibeBack) => {
      if (!exibeBack) {
        this.newsletterEdicao = undefined;
      }
    });
  }
}
