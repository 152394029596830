import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconeInteiroTeorComponent } from './icone-inteiro-teor.component';



@NgModule({
    imports: [
        CommonModule,
        IconeInteiroTeorComponent
    ],
    exports: [
        IconeInteiroTeorComponent
    ]
})
export class IconeInteiroTeorModule { }
