import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconeCopiarComponent } from './icone-copiar.component';



@NgModule({
    imports: [
        CommonModule,
        IconeCopiarComponent
    ],
    exports: [
        IconeCopiarComponent
    ]
})
export class IconeCopiarModule { }
