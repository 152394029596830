import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiarioOficialUsuarioComponent } from './diario-oficial-usuario.component';
import { SegmentoEstadosModule } from '../segmento-estados/segmento-estados.module';


@NgModule({
  declarations: [DiarioOficialUsuarioComponent],
  imports: [
    CommonModule,
    SegmentoEstadosModule
  ],
  exports:[
    DiarioOficialUsuarioComponent
  ]
})
export class DiarioOficialUsuarioModule { }
