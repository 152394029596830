import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AgendaPageRoutingModule } from './agenda-routing.module';

import { AgendaPage } from './agenda.page';
import { IconeEmentaModule } from '../icone-ementa/icone-ementa.module';
import { IconeCopiarModule } from '../icone-copiar/icone-ementa.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        IconeEmentaModule,
        IconeCopiarModule,
        AgendaPageRoutingModule,
        AgendaPage
    ]
})
export class AgendaPageModule {}
