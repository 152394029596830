/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, LoadingController, Platform, ModalController, IonicModule } from '@ionic/angular';
import { AgendaSessaoService } from '../api/agenda-sessao.service';
import { HomeService } from '../api/home.service';
import { PushService } from '../api/push.service';
import { RadarMonitoramentoService } from '../api/radar-monitoramento.service';
import { UsuarioService } from '../api/usuario.service';
import { MenuService } from '../util/menu.service';
import { PageBaseService } from '../util/page-base.service';
import { ProjetoService } from '../api/projeto.service';
import { ProposicaoPage } from '../proposicao/proposicao.page';
import { AgendaPage } from '../agenda/agenda.page';
import { ParlamentarService } from '../api/parlamentar.service';
import { RadarEdicaoPage } from '../radar-edicao/radar-edicao.page';
import { CriterioRankingPage } from '../criterio-ranking/criterio-ranking.page';
import { ComissaoService } from '../api/comissao.service';
import { SessaoPlenarioService } from '../api/sessao-plenario.service';
import { VotoParlamentarPage } from '../voto-parlamentar/voto-parlamentar.page';
import { ParlamentarComponent } from '../parlamentar/parlamentar.component';
import { DocumentoProjetoService } from '../api/documento-projeto.service';
import { OrdemDiaService } from '../api/ordem-dia.service';
import { OrdemDiaPage } from '../ordem-dia/ordem-dia.page';
import { RadarSlideComponent } from '../radar-slide/radar-slide.component';
import { AssinaturaService } from '../api/assinatura.service';
import { RetrospectivaService } from '../api/retrospectiva.service';
import { ComissaoPage } from '../comissao/comissao.page';
import { NoticiaService } from '../api/noticia.service';
import { TwitterService } from '../api/twitter.service';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { Title } from '@angular/platform-browser';
import { ProjetoVisualizadoService } from '../api/projeto-visualizado.service';
import { PainelMenuPage } from '../painel-menu/painel-menu.page';
import { ConfiguracaoNotificacaoPage } from '../configuracao-notificacao/configuracao-notificacao.page';
import { ChartSelectEvent, GoogleChartInterface, Ng2GoogleChartsModule } from 'ng2-google-charts';
import { Vibration } from '@awesome-cordova-plugins/vibration/ngx';
import { DiarioOficialService } from '../api/diario-oficial.service';
import { ClienteService } from '../api/cliente-service.service';
import { ParlamentarVisualizadoService } from '../api/parlamentar-visualizado.service';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IconeEmentaComponent } from '../icone-ementa/icone-ementa.component';
import { ContadorProposicaoComponent } from '../contador-proposicao/contador-proposicao.component';
import { AgendaComponentPage } from '../agenda-component/agenda-component.page';
import { AgendaExecutivoComponentPage } from '../agenda-executivo-component/agenda-executivo-component.page';
import { DiarioOficialUsuarioComponent } from '../diario-oficial-usuario/diario-oficial-usuario.component';

@Component({
    selector: 'app-Home',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],
    imports: [
        IonicModule,
        NgIf,
        FormsModule,
        NgFor,
        Ng2GoogleChartsModule,
        RadarEdicaoPage,
        IconeEmentaComponent,
        // ContadorProposicaoComponent,
        AgendaComponentPage,
        AgendaExecutivoComponentPage,
        DiarioOficialUsuarioComponent,
        NgClass,
        DatePipe,
    ],
})
export class HomePage extends PageBaseService implements OnInit {
  @Input()
  segmentoFiltro: any;
  width = this.platform.width() as any;
  homeVM: any;
  clienteLista: any = [];
  segmento = 'novidades';
  tendenciaLista: any;
  filtroPartido = 'Todos os partidos';
  ranking = { relevanciaLista: [], relevanciaListaBkp: [] };
  partidoLista: any;
  tweetLista: any[];
  clienteSelecionado: any;
  cradarSelecionado: any;
  radarFiltroSelected = true;
  filtroRanking = {
    Deputado: true,
    Senador: true,
    InicioRanking: true,
    Pagina: 1,
    QtdRegistroPagina: 10,
    Next: false,
  };
  android = true;
  idNotificacao: any;
  dataAtual = new Date().toLocaleString().split(',')[0].replace(',', '');
  radarUsuarioLista: any[];
  radarUsuarioListaBkp: any[];
  radarFiltro = null;
  exibirSpinning = false;
  projetoVisualizadoLista: any;
  plano = null;
  noticiaLista: any;
  public columnChart4: GoogleChartInterface;
  public columnChart5: GoogleChartInterface;

  arrayEvolucao: any[][];
  arrayEvolucaoDiario: any[][];
  comparacaoMovimentacao: any;
  textoAgenda: any;
  radarUsuarioListaResultado = [];
  constructor(
    protected platform: Platform,
    public router: Router,
    protected zone: NgZone,
    private menuService: MenuService,
    private ordemDiaService: OrdemDiaService,
    private projetoService: ProjetoService,
    private documentoService: DocumentoProjetoService,
    public modalController: ModalController,
    private radarMonitoramentoService: RadarMonitoramentoService,
    private sessaoPlenarioService: SessaoPlenarioService,
    private parlamentarService: ParlamentarService,
    private agendaSessaoService: AgendaSessaoService,
    private homeService: HomeService,
    private usuarioService: UsuarioService,
    protected pushService: PushService,
    protected loadingCtrl: LoadingController,
    private comissaoService: ComissaoService,
    protected alertCtrl: AlertController,
    private retrospectivaService: RetrospectivaService,
    public route: ActivatedRoute,
    public assinaturaService: AssinaturaService,
    public noticiaService: NoticiaService,
    public twitterService: TwitterService,
    private titleService: Title,
    private vibration: Vibration,
    private projetoVisualizadoService: ProjetoVisualizadoService,
    private parlamentarVisualizadoService: ParlamentarVisualizadoService,
    private clienteService: ClienteService,
    private diarioOficialService: DiarioOficialService
  ) {
    super(loadingCtrl, alertCtrl, platform, router);
  }

  ionViewWillEnter() {
    this.vibration.vibrate(2000);
    this.tratamentoPushNotification();
    this.menuService.publish('');
    this.menuService.publishBack(false);
  }

  ngOnInit(): void {
    try {
      this.titleService.setTitle(
        'Legislapp • Página Inicial do monitoramento legislativo'
      );
      if (this.usuarioService.get() && Capacitor.isNativePlatform()) {
        if (this.platform.is('android')) {
          this.android = true;
          this.pushService.initialize(this.usuarioService.get().Email);
        } else {
          this.pushService.initialize(this.usuarioService.get().Email);
          // this.pushService.initializeIos(this.usuarioService.get().Email);
        }
      }
      if (this.usuarioService.get()) {
        setInterval(() => {
          if (localStorage.getItem('RADAR_MODIFICADO')) {
            this.zone.run(async () => {
              localStorage.removeItem('RADAR_MODIFICADO');
              this.carregarLista();
            });
          }
        }, 5000);
        if (window.location.href.split('/').pop() == 'agenda-executivo') {
        } else this.carregarLista();
        // this.carregarProgresso();
        // this.carregarProgressoDiario();
        this.usuarioService
          .obterPlano(
            this.usuarioService.get() ? this.usuarioService.get().Id : 0
          )
          .then((plano) => (this.plano = plano));
          this.exibirTutorialRadar();

        if (localStorage.getItem('TUTORIAL') == 'true') {
          this.ocultarLoading();
          // this.exibirTutorialRadar();
        }
      }
    } catch (error) {
      this.router.navigateByUrl('/tabs/proposicoes');
    }
  }
  limparFiltroCliente() {
    this.clienteSelecionado = undefined;
    this.radarUsuarioLista = this.radarUsuarioListaBkp;
  }

  filtrarPorCliente(ev: any) {
    localStorage.removeItem('PROPOSICAO_RADAR_LISTA');
    this.radarUsuarioLista = this.radarUsuarioListaBkp.filter((x) =>
      x.ClienteLista.map((x) => x.Id).some(
        (o) => ev.target.value.indexOf(o) > -1
      )
    );
    if (this.radarUsuarioLista.length > 0)
      this.radarUsuarioListaResultado = this.radarUsuarioLista;
    else this.limparFiltroCliente();
  }
  public evolucaoDiarioClick(event: ChartSelectEvent) {
    if (event.columnLabel) this.verRadarClick(event.columnLabel);
  }

  carregarProgresso() {
    this.homeService
      .listarProgresso(this.usuarioService.get().Id)
      .then((data) => {
        var data2 = data as any;
        this.comparacaoMovimentacao = data2.Comparacao;
        this.arrayEvolucao = data2.ProgressoAtual;
        this.columnChart4 = {
          chartType: 'ScatterChart',
          dataTable: this.arrayEvolucao,
          options: {
            width: 900,
            chartArea: { width: '800' },
            vAxis: {
              title: 'Andamentos',
              titleTextStyle: { color: '#069' },
            },
            hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
            lineWidth: 1,
            legend: {
              position: 'in',
            },
          },
        };
      });
  }

  carregarProgressoDiario() {
    this.diarioOficialService
      .listarProgresso(this.usuarioService.get().Id)
      .then((data) => {
        this.exibirSpinning = false;
        var data2 = data as any;
        this.arrayEvolucaoDiario = data2.ProgressoAtual;
      });
  }

  async exibirTutorialRadar() {
    const modal = await this.modalController.create({
      component: RadarSlideComponent,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  tratamentoPushNotification() {
    if (window.location.href.indexOf('dou') > -1) {
      var id = window.location.href.split('=')[1];
      this.segmentoChange({ detail: { value: 'diario' } });
    }
    if (window.location.href.indexOf('agenda') > -1) {
      this.segmentoChange({ detail: { value: 'agenda' } });
    }
    if (window.location.href.indexOf('agenda-executivo') > -1) {
      this.segmentoChange({ detail: { value: 'agendaExecutivo' } });
    }
    if (window.location.href.indexOf('noticia') > -1) {
      this.segmentoChange({ detail: { value: 'noticia' } });
      this.segmento = 'noticia';
    }
    this.route.queryParams.subscribe((params) => {
      if (params.Id && params.Tipo && params.Id != this.idNotificacao) {
        this.idNotificacao = params.Id;
        setTimeout(this.ocultarLoading, 3000);
        switch (params.Tipo) {
          case 'PROPOSICAO':
            this.proposicaoClick({ IdProjeto: params.Id });
            break;
          case 'DOU':
            this.segmentoChange({ detail: { value: 'diario' } });
            break;
          case 'NOTICIA':
            this.segmentoChange({ detail: { value: 'noticia' } });
            break;
          case 'ORDEM_DIA':
            this.ordemDiaClick();
            break;
          case 'MINISTRO':
            this.router.navigateByUrl('/tabs/autoridades');
            break;
          case 'DOCUMENTO':
            this.abrirDocumento(params.Id);
            break;
          case 'TWEET_PROJETO':
            this.abrirTweetProjeto(params.Id);
            break;
          case 'EVENTO':
            this.agendaSessaoService
              .obter(params.Id)
              .then((data) => this.agendaClick(data));
            break;
          case 'COMISSAO':
            this.comissaoClick(params.Id);
            break;
          case 'PARLAMENTAR':
            this.parlamentarClick({ Id: params.Id });
            break;
          case 'ASSINATURA':
            this.assinaturaService.inicializar(this.usuarioService.get().Id);
            this.assinaturaService.assinar();
            break;
          case 'TELEGRAM':
            this.exibirMenu();
            break;
          case 'SESSAO_PLENARIO':
            this.sessaoPlenarioService.obter(params.Id).then((data) => {
              this.sessaoClick(data);
            });
            break;
          default:
            break;
        }
        this.usuarioService.visualizarNotificacao(params.Id);
      }

      if (params.NomePlano) {
        alert('Assinatura Ativada!');
      }
    });
  }

  async exibirMenu() {
    const modal = await this.modalController.create({
      component: ConfiguracaoNotificacaoPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  abrirTweetProjeto(idProjeto) {
    this.subscription.unsubscribe();
    this.exibirLoading('Carregando...');

    this.projetoService.obter({ Id: idProjeto }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ProposicaoPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          proposicao: data,
          aba: 'twitter',
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  comissaoClick(id) {
    this.exibirLoading('Carregando...');
    this.comissaoService.obter({ Id: id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ComissaoPage,
        cssClass: 'modal-pontuacao',
        componentProps: {
          comissao: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  abrirDocumento(idDocumento) {
    this.subscription.unsubscribe();
    this.exibirLoading('Carregando...');
    this.documentoService
      .obter(idDocumento)
      .then(async (dataDocumento: any) => {
        this.projetoService
          .obter({ Id: dataDocumento.IdProjeto })
          .then(async (data) => {
            const modal = await this.modalController.create({
              component: ProposicaoPage,
              cssClass: 'modal-pontuacao',
              componentProps: {
                proposicao: data,
                aba: 'documentos',
              },
            });
            this.ocultarLoading();
            return await modal.present();
          });
      });
  }
  ordemDiaClick() {
    if (this.plano) {
      this.subscription.unsubscribe();
      this.exibirLoading('Carregando...');
      this.ordemDiaService
        .listar({ data: new Date() })
        .then(async (listaOrdemDia: any) => {
          const modal = await this.modalController.create({
            component: OrdemDiaPage,
            cssClass: 'modal-pontuacao',
            componentProps: {
              listaOrdemDia,
              aba: 'documentos',
            },
          });
          this.ocultarLoading();
          return await modal.present();
        });
    } else {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {
          this.plano = data;
        });
      this.assinaturaService.assinar();
    }
  }

  onImgError(item) {
    if (item.Autor == 'Senado Federal') {
      item.Foto = '/assets/logo-senado.png';
      item.UrlFotoAutor = '/assets/logo-senado.png';
    } else if (item.Autor == 'Câmara dos Deputados') {
      item.Foto = '/assets/logo-camara.png';
      item.UrlFotoAutor = '/assets/logo-camara.png';
    } else if (
      item.Autor == 'Poder Executivo' ||
      item.Autor == 'Presidência da República'
    ) {
      item.Foto = '/assets/poderexecutivo.png';
      item.UrlFotoAutor = '/assets/poderexecutivo.png';
    } else if (item.Autor.toUpperCase().indexOf('COMISSÃO') > -1) {
      item.Foto = '/assets/comissoes.png';
      item.UrlFotoAutor = '/assets/comissoes.png';
    } else {
      item.Foto = '/assets/imagem-perfil.jpg';
      item.UrlFotoAutor = '/assets/imagem-perfil.jpg';
    }
  }

  async parlamentarClick(rankingItem) {
    this.exibirLoading('Carregando...');
    this.parlamentarService.obter({ Id: rankingItem.Id }).then(async (data) => {
      const modal = await this.modalController.create({
        component: ParlamentarComponent,
        cssClass: 'modal-pontuacao',
        componentProps: {
          parlamentar: data,
        },
      });
      this.ocultarLoading();
      return await modal.present();
    });
  }

  proposicaoCasaClick(segmento) {
    const navigationExtras: NavigationExtras = {
      queryParams: { segmento },
    };
    this.router.navigateByUrl(
      '/proposicoes?segmento=' + segmento,
      navigationExtras
    );
  }

  async agendaClick(agenda) {
    if (this.plano) {
      this.zone.run(async () => {
        this.subscription.unsubscribe();
        this.exibirLoading('Carregando...');
        const modal = await this.modalController.create({
          component: AgendaPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            agenda,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
    } else {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {
          this.plano = data;
        });
      this.assinaturaService.assinar();
    }
  }

  async proposicaoClick(acontecimento) {
    this.subscription.unsubscribe();
    this.exibirLoading('Carregando...');
    this.projetoService
      .obter({ Id: acontecimento.IdProjeto })
      .then(async (data) => {
        const modal = await this.modalController.create({
          component: ProposicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            proposicao: data,
          },
        });
        this.ocultarLoading();
        return await modal.present();
      });
  }

  async sessaoClick(projetoSessaoPlenario) {
    this.exibirLoading('Carregando...');
    const modal = await this.modalController.create({
      component: VotoParlamentarPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        projetoSessaoPlenario,
      },
    });
    this.ocultarLoading();
    return await modal.present();
  }
  radarChange($event) {
    if (this.plano) {
      if ($event && $event.detail.value !== false) {
        var radarSelecionado = this.radarUsuarioLista.filter(
          (x) => x.Titulo == $event.detail.value.trim()
        )[0];
        this.radarFiltro = radarSelecionado;
        this.carregarLista();
      }
    } else {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {
          this.plano = data;
        });
      this.assinaturaService.assinar();
    }
  }

  compareWithRadar(o1: any, o2: any) {
    if (o1 === 'Todos os radares' || o1 === true) return true;
    return o1 && o2 ? o1 === o2 : o1 === o2;
  }

  compareWith(o1: any, o2: any) {
    if (o2 === 'Todos os partidos') return true;
    return o1 === o2;
  }
  navegarAgenda() {
    window.location.href = '/#/agenda';
    window.location.reload();
  }
  segmentoChange($event) {
    if (!this.plano && this.usuarioService.get()) {
      this.usuarioService
        .obterPlano(this.usuarioService.get().Id)
        .then((plano) => {
          this.plano = plano;
          if (!this.plano) {
            this.assinaturaService.assinar();
          }
        });
    }
    if ($event) {
      this.segmento = $event.detail.value;
      if (this.segmento === 'agenda') {
        // this.carregarAgenda();
      } else if (this.segmento === 'tendencia') {
        this.exibirLoading('');
        this.radarMonitoramentoService.tendencia().then((data) => {
          this.tendenciaLista = data;
          this.ocultarLoading();
        });
      } else if (this.segmento === 'ranking') {
        this.exibirLoading('');
        this.carregarRanking();
      } else if (this.segmento === 'noticia') {
        this.exibirLoading('');
        this.carregarNoticia();
      } else if (this.segmento === 'twitter') {
        this.exibirSpinning = true;
        var that = this;
        setTimeout(() => {
          that.exibirSpinning = false;
        }, 6000);
        this.carregarTweets();
      } else if (this.segmento === 'novidades') {
        this.exibirSpinning = true;
        var that = this;
        setTimeout(() => {
          that.exibirSpinning = false;
        }, 8000);
        this.carregarProgresso();
        // this.carregarProgressoDiario();
      } else if (this.segmento === 'diario') {
        // this.carregarProgressoDiario();
        // this.columnChart5 = {
        //   chartType: 'ScatterChart',
        //   dataTable: this.arrayEvolucaoDiario,
        //   options: {
        //     width: 900,
        //     chartArea: { width: '800' },
        //     vAxis: {
        //       title: 'Publicações',
        //       titleTextStyle: { color: '#069' },
        //     },
        //     hAxis: { title: 'Dia', titleTextStyle: { color: '#069' } },
        //     lineWidth: 1,
        //     legend: {
        //       position: 'in',
        //     },
        //   },
        // };
      }
    }
  }

  noticiaClick(noticia) {
    window.open(noticia.Url, '_system');
    if (!this.plano) {
      this.assinaturaService
        .inicializar(this.usuarioService.get().Id)
        .subscribe((data) => {});
      this.assinaturaService.assinar();
    }
  }

  onImgErrorNoticia(item) {
    item.UrlImagem =
      'https://legislapp.com.br/wp-content/uploads/Prancheta-289.png';
  }

  shareNoticia(noticia) {
    var texto = `${noticia.Descricao}`;
    var link = noticia.Url;
    Share.share({
      title: noticia.Title,
      text: texto,
      url: link,
      dialogTitle: 'Compartilhe o legislativo onde quiser!',
    })
      .then((arg) => {})
      .catch((error) => {
        // alert(JSON.stringify(error));
        this.loading.dismiss();
      });
  }

  shareTweet(tweet) {
    var texto = `${tweet.Descricao}`;
    var link = tweet.Url;
    Share.share({
      title: tweet.Descricao,
      text: texto,
      url: link,
      dialogTitle: 'Compartilhe o legislativo onde quiser!',
    })
      .then((arg) => {})
      .catch((error) => {
        // alert(JSON.stringify(error));
        this.loading.dismiss();
      });
  }

  partidoChange($event) {
    if (
      $event &&
      $event.detail.value !== false &&
      $event.detail.value !== 'Todos os partidos'
    ) {
      this.ranking.relevanciaLista = this.ranking.relevanciaListaBkp.filter(
        (x) => x.Partido == $event.detail.value
      );
    } else {
      this.zone.run(async () => {
        this.ranking.relevanciaLista = this.ranking.relevanciaListaBkp;
      });
    }
  }

  carregarRanking() {
    this.parlamentarService
      .rankingRelevancia(this.filtroRanking)
      .then((data) => {
        this.zone.run(async () => {
          this.ranking.relevanciaLista = data as any[];
          this.ranking.relevanciaListaBkp = data as any[];
          this.partidoLista = this.ranking.relevanciaListaBkp
            .map((x) => x.Partido)
            .filter((v, i, a) => a.indexOf(v) === i)
            .sort();
          this.partidoLista.unshift('Todos os partidos');
          this.ocultarLoading();
        });
        this.ocultarLoading();
      });
  }

  filtroRankingClick(tipo, valor) {
    const filtro = {
      InicioRanking: this.filtroRanking.InicioRanking,
      Deputado: this.filtroRanking.Deputado,
      Senador: this.filtroRanking.Senador,
      Pagina: this.filtroRanking.Pagina,
      Next: this.filtroRanking.Next,
      QtdRegistroPagina: this.filtroRanking.QtdRegistroPagina,
    };
    if (tipo) {
      if (tipo == 'deputado') {
        filtro.Deputado = !valor;
      } else {
        filtro.Senador = !valor;
      }
    }

    this.exibirLoading('');
    this.parlamentarService.rankingRelevancia(filtro).then((data) => {
      if (this.filtroRanking.Next) {
        this.ranking.relevanciaLista =
          this.ranking.relevanciaLista.concat(data);
      } else {
        this.ranking.relevanciaLista = data as any[];
      }
      this.ranking.relevanciaListaBkp = this.ranking.relevanciaLista;
      this.zone.run(async () => {
        this.partidoLista = this.ranking.relevanciaListaBkp
          .map((x) => x.Partido)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();
        this.partidoLista.unshift('Todos os partidos');
        this.ocultarLoading();
      });
    });
  }

  inverterOrdemRankingClick() {
    this.filtroRanking.InicioRanking = !this.filtroRanking.InicioRanking;
    this.filtroRanking.Pagina = 1;
    this.carregarRanking();
  }

  async verRadarClick(radar) {
    var usuario = this.usuarioService.get();
    if (!usuario) {
      window.location.href = '/#/primeiro-acesso';
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return;
    }
    this.modalController.dismiss();
    this.radarMonitoramentoService
      .listar(this.usuarioService.get().Id)
      .then(async (data: any[]) => {
        radar = data.find((x) => x.Titulo == radar);
        const modal = await this.modalController.create({
          component: RadarEdicaoPage,
          cssClass: 'modal-pontuacao',
          componentProps: {
            radar,
          },
        });
        return await modal.present();
      });
  }

  async tendenciaClick(tendencia) {
    const modal = await this.modalController.create({
      component: RadarEdicaoPage,
      cssClass: 'modal-pontuacao',
      componentProps: {
        tendencia: tendencia.Value,
      },
    });
    return await modal.present();
  }

  async informacaoRankingRelevanciaClick() {
    const modal = await this.modalController.create({
      component: CriterioRankingPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  rankingCompletoClick() {
    this.filtroRanking.Next = true;
    this.filtroRanking.Pagina = this.filtroRanking.Pagina + 1;
    this.filtroRankingClick(null, null);
  }
  acontecimentoClick(acontecimento) {
    if (acontecimento.IsParlamentar) this.parlamentarClick(acontecimento);
    else this.proposicaoClick(acontecimento);
  }
  carregarLista($event = null) {
    this.clienteService
      .listarPorUsuarioAdmin(this.usuarioService.get().Id)
      .then((data: any) => {
        this.clienteLista = data;
      });
    // this.exibirLoading('Carregando...');
    this.exibirSpinning = true;
    // var that = this;
    // setTimeout(() => {
    //   that.exibirSpinning = false;
    // }, 8000);
    this.homeVM = {};

    this.projetoVisualizadoService
      .listar(this.usuarioService.get().Email)
      .then((data2: any) => {
        this.parlamentarVisualizadoService
          .listar(this.usuarioService.get().Email)
          .then((data3: any) => {
            this.homeVM.ListaAcesso = data3.concat(data2);
          });
      });
    this.ocultarLoading();
    if ($event) {
      $event.target.complete();
    }
    this.zone.run(() => {
      this.radarMonitoramentoService
        .listar(this.usuarioService.get().Id)
        .then((data: any[]) => {
          var that = this;
          setTimeout(() => {
            that.exibirSpinning = false;
          }, 8000);
          var tagCount = (data as any).flatMap((x) => x.Tags).filter((x) => x);
          if (
            tagCount.length < 100 ||
            (this.radarFiltro && this.radarFiltro.Id)
          ) {
            // this.radarUsuarioLista = [...[{
            //   Titulo: 'Todos os radares',
            //   Id: null,
            // }], ...data];
            this.radarUsuarioLista = [...data];
            this.radarUsuarioListaBkp = this.radarUsuarioLista;
            if (this.clienteLista.length > 5) {
              this.clienteSelecionado = this.clienteLista[0].Id;
              this.filtrarPorCliente({
                target: { value: this.clienteLista[0].Id },
              });
            }
          }
          if (this.radarFiltro && this.radarFiltro.Id)
            this.radarUsuarioListaResultado = this.radarUsuarioLista.filter(
              (x) => x.Id == this.radarFiltro.Id
            );
          else this.radarUsuarioListaResultado = [...this.radarUsuarioLista];

          if ($event) {
            $event.target.complete();
          }
        });
    });
  }

  carregarHtmlTweets() {
    var size = 20 > this.tweetLista.length ? this.tweetLista.length : 20;
    var that = this;
    this.zone.run(() => {
      this.tweetLista.slice(0, size).forEach(function (e, index) {
        that.parlamentarService.obterTwitterHtml(e).then((html) => {
          that.tweetLista[index].html = html;
        });
      });
    });
  }
  carregarTweets(event = undefined) {
    this.twitterService.listarRecentes().then((data) => {
      this.tweetLista = data as any[];
      this.carregarHtmlTweets();
      var that = this;
      if (event) {
        event.target.complete();
      }
      setTimeout(() => {
        (window as any).twttr.widgets.load();
      }, 1000);
      setTimeout(() => {
        (window as any).twttr.widgets.load();
      }, 6000);
    });
  }

  carregarNoticia(event = undefined) {
    if (this.noticiaLista == null || event) {
      this.noticiaService.listarRecente().then((data) => {
        this.zone.run(async () => {
          this.noticiaLista = data;
          this.ocultarLoading();
        });
        this.ocultarLoading();
        if (event) {
          event.target.complete();
        }
      });
    } else {
      this.ocultarLoading();
    }
  }
}
