import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconeEmentaComponent } from './icone-ementa.component';



@NgModule({
    imports: [
        CommonModule,
        IconeEmentaComponent
    ],
    exports: [
        IconeEmentaComponent
    ]
})
export class IconeEmentaModule { }
