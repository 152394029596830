import { Component, OnInit } from '@angular/core';
import {
  ModalController,
  LoadingController,
  Platform,
  AlertController,
} from '@ionic/angular';
import { Router } from '@angular/router';
import { UsuarioService } from '../api/usuario.service';
import { ConfiguracaoNotificacaoPage } from '../configuracao-notificacao/configuracao-notificacao.page';
import { RadarMonitoramentoPage } from '../radar-monitoramento/radar-monitoramento.page';
import { MeuPlanoPage } from '../meu-plano/meu-plano.page';
import { ContatoPage } from '../contato/contato.page';
import { MeusClientesPage } from '../meus-clientes/meus-clientes.page';
import { MeusLogsPage } from '../meus-logs/meus-logs.page';


@Component({
  selector: 'app-painel-menu',
  templateUrl: './painel-menu.page.html',
  styleUrls: ['./painel-menu.page.scss'],
})
export class PainelMenuPage implements OnInit {
  email = localStorage.getItem('EMAIL');
  loading: HTMLIonLoadingElement;
  extrato: any;
  ios = false;
  usuario: any;
  constructor(
    public modalController: ModalController,
    private router: Router,
    private usuarioService: UsuarioService,
    private loadingController: LoadingController,
    public platform: Platform,
    private alertController: AlertController
  ) { }

  ngOnInit() {
    this.ios = this.platform.is('ios');
    this.usuario = this.usuarioService.get();
    if (!this.usuario) {
      window.location.href = '/#/primeiro-acesso';
      window.location.reload();
      setTimeout(() => {
        this.fechar();
      }, 1000);
    }
    if (!this.usuario.ImagemPerfil) {
      this.usuario.ImagemPerfil =
        'https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y';
    }
  }

  async criarLoading(texto: string) {
    return await this.loadingController.create({
      message: texto,
    });
  }
  async excluirConta() {
    const alert = await this.alertController.create({
      header: 'Essa ação é irreversível',
      subHeader: '',
      message:
        'Você tem certeza que deseja excluir sua conta? Seus radares e configurações pessoais da plataforma serão excluídos.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => { },
        },
        {
          text: 'Confirmar',
          role: 'confirm',
          handler: () => {
            localStorage.clear();
            window.location.reload();
          },
        },
      ],
    });

    await alert.present();
  }
  async configuracaoNotificacaoClick() {
    const modal = await this.modalController.create({
      component: ConfiguracaoNotificacaoPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  async meuPlanoClick() {
    const modal = await this.modalController.create({
      component: MeuPlanoPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }
  async meusClientesClick() {
    const modal = await this.modalController.create({
      component: MeusClientesPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  async meusLogsClick() {
    const modal = await this.modalController.create({
      component: MeusLogsPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  async radarClick() {
    const modal = await this.modalController.create({
      component: RadarMonitoramentoPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  async contatoClick() {
    const modal = await this.modalController.create({
      component: ContatoPage,
      cssClass: 'modal-pontuacao',
    });
    return await modal.present();
  }

  async exibirLoading(texto: string): Promise<HTMLIonLoadingElement> {
    return new Promise<HTMLIonLoadingElement>(async (resolve) => {
      this.criarLoading(texto).then(async (x) => {
        this.loading = x;
        await this.loading.present();
        resolve(x);
      });
    });
  }

  newsletterClick() {
    window.location.href = '/#/newsletter-lista';
    window.location.reload();
  }

  fechar() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({});
  }

  desconectar() {
    localStorage.clear();
    window.location.href = '/#/primeiro-acesso';
    window.location.reload();
    this.fechar();
    // this.router.navigateByUrl('/primeiro-acesso');
  }
}
