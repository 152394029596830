import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssinaturaComponent } from './assinatura.component';
import { AssinaturaPageRoutingModule } from './assinatura-routing.module';



@NgModule({
    imports: [
        CommonModule,
        AssinaturaPageRoutingModule,
        AssinaturaComponent
    ],
    exports: [AssinaturaComponent]
})
export class AssinaturaModule { }
