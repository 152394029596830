import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgendaComponentPage } from './agenda-component.page';
import { AgendaPageModule } from '../agenda/agenda.module';
import { IconeEmentaModule } from '../icone-ementa/icone-ementa.module';
import { TypeaheadPageModule } from '../typeahead/typeahead.module';



@NgModule({
    imports: [
        CommonModule,
        AgendaPageModule,
        IconeEmentaModule,
        TypeaheadPageModule,
        AgendaComponentPage
    ],
    exports: [
        AgendaComponentPage
    ]
})
export class AgendaComponentPageModule { }
